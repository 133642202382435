import { KeyType } from 'api/CertificateService/enums';
import { handlePasswordError } from 'api/CertificateService/handlePasswordError';
import { FilterKeystore, KeyStore, Token } from 'api/CertificateService/interfaces';
import { ActionEnum } from 'api/enums';
import config from 'config';
import { CustomError } from 'utils/customError';

import client from './client';
import { CertificateUmka, KeyRequest, SigType } from './interfaces';

export const isUmcaAvailable = async (): Promise<boolean> => {
  try {
    await client.get('/keystores?ReadCertificates=true');
    return true;
  } catch (e) {
    return false;
  }
};

export const getKeyStores = async (type: KeyType): Promise<Token[]> => {
  const filter = {
    ReadCertificates: true,
    EnfoldFileKeyStores: true,
    Hw: KeyType.TOKEN === type,
  };

  const { data } = await client.get('/keystores', {
    params: filter,
  });

  return data;
};

export const checkPin = async (pin: string, keyStore: Partial<KeyStore>) => {
  try {
    await client.post('/keystores/checkpin', {
      pin: pin,
      keyStore: keyStore,
    });
  } catch (e: any) {
    throw new CustomError(await handlePasswordError(e.message, ActionEnum.SignAuthor));
  }
};

export const generateKey = async (
  pin: string,
  keyStore: KeyStore,
  keyRequest: KeyRequest,
): Promise<CertificateUmka> => {
  const request = {
    Pin: pin,
    KeyRequest: {
      ...keyRequest,
      KeyUsage: 's',
      KeyParams: {
        AlgOid: config.utils.ecp.ALGORITM,
        KeyLen: null as null,
      },
    },
    KeyStore: keyStore,
  };

  const { data } = await client.post('/certificates', request);

  return data;
};

export const updateCertificate = async (pin: string, certificate: string): Promise<Obj> => {
  const request = new FormData();
  request.append('Certificate', certificate);
  request.append('Pin', pin);

  const { data } = await client.patch('/certificates', request);

  return data;
};

export const sign = async (
  data: string | Blob,
  certificate: string,
  pin: string,
  type?: SigType,
  alg?: string | Blob,
  cms?: string | Blob,
): Promise<string> => {
  const request = new FormData();
  const sigType = type || 'cms-attached';

  request.append('Certificate', certificate);
  request.append('Data', data);
  request.append('Pin', pin);
  request.append('SigType', sigType);

  if (alg) {
    request.append('DigestAlgOid', alg);
  }
  if (cms) {
    request.append('Cms', cms);
  }

  const { data: signedCertificate } = await client.post('/sign', request);

  return signedCertificate;
};

export const multiSign = async (
  multipleData: string[],
  certificate: string,
  pin: string,
  multipleCms: string[] | undefined,
  sigType?: SigType,
) => {
  const request = {
    Pin: pin,
    SigType: sigType,
    MultipleData: multipleData,
    Certificate: certificate,
    MultipleCms: multipleCms,
  };

  const { data: signedStringList } = await client.post('/sign/multiple', request);

  return signedStringList;
};

const versionRegExp = /\d+\.\d+\.\d*/;
const VERSION_SEPARATOR = '.';

export const getUmcaVersion = (server: string): number => {
  const [stringVersion] = server.match(versionRegExp);
  return parseInt(stringVersion.replaceAll(VERSION_SEPARATOR, ''));
};

const VERSION_UMCA_WITH_MULTISIGN = 389;

export const isUmcaVersionAvailable = async (): Promise<boolean> => {
  try {
    const { headers } = await client.get('/keystores');
    return getUmcaVersion(headers.server) >= VERSION_UMCA_WITH_MULTISIGN;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const deleteKey = async (pin: string, certificate: string): Promise<boolean> => {
  const request = new FormData();
  request.append('Pin', pin);
  request.append('Certificate', certificate);

  const { data: resultDelete } = await client.delete('/certificates', {
    data: request,
  });

  return resultDelete;
};

export const getCertificatesExtended = async (filter: FilterKeystore): Promise<Token[]> => {
  const { data } = await client.get('/keystores', {
    params: filter,
  });

  return data;
};

export const getCertificate = async (certificate: string): Promise<Token> => {
  const dataFilter = {
    Certificate: certificate,
  };

  const { data } = await client.post('/keystores/select', dataFilter);

  return data;
};

export const changePin = async (
  oldPin: string,
  newPin: string,
  keyStoreInfo: KeyStore,
  action: ActionEnum,
): Promise<Obj> => {
  const request = {
    OldPin: oldPin,
    NewPin: newPin,
    KeyStore: keyStoreInfo,
  };
  try {
    const { data } = await client.patch('/keystores', request);

    return data;
  } catch (e: any) {
    throw new CustomError(await handlePasswordError(e.message, action));
  }
};
