import React from 'react';

import classNames from 'classnames';

import { bem } from 'components/utils/bem';

interface DataColumnProps {
  children?: React.ReactNode;
  subTitle?: string | number;
  subTitleClassName?: string;
  subTitleWrap?: boolean;
  title?: string | number | Date | JSX.Element | JSX.Element[];
  titleClassName?: string;
  titleWrap?: boolean;
  topSubTitle?: string | number;
  topSubTitleClassName?: string;
}

export const DataColumn = (props: DataColumnProps) => {
  const {
    children,
    title,
    subTitle,
    topSubTitle,
    titleClassName,
    subTitleClassName,
    topSubTitleClassName,
    titleWrap,
  } = props;

  const subTitleClassNames = classNames('data-table-cell-subtitle', subTitleClassName);
  const topSubTitleClassNames = classNames('data-table-cell-subtitle', topSubTitleClassName);
  const titleClassNames = bem('data-table-cell-title')
    .modificator('isWrapped', titleWrap)
    .toClassName(titleClassName);

  return (
    <>
      {topSubTitle && <span className={topSubTitleClassNames}>{topSubTitle}</span>}
      {title && (
        <span title={`${title}`} className={titleClassNames}>
          {title}
        </span>
      )}
      {subTitle && (
        <span title={`${subTitle}`} className={subTitleClassNames}>
          {subTitle}
        </span>
      )}
      {children}
    </>
  );
};
