import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { CheckSmsActionEnum } from 'api/enums';
import { Counterparties } from 'api/HandbooksService';
import { SortOrder } from 'api/Service';
import { ConfirmationSmsModal } from 'components/modals/ConfirmationSmsModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { allowPrivilegy, isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';

import { CounterpartiesPageFilter } from './CounterpartiesPageFilter';

const columns: TableColumnProps<Counterparties>[] = [
  {
    label: 'front.сounterparties-table.name.label',
    render: row => <DataColumn title={row.name} />,
    sort: 'name',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.сounterparties-table.customer-name.label',
    render: row => <DataColumn title={row.customerName} />,
    sort: 'customer.name',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.сounterparties-table.bin.label',
    render: row => <DataColumn title={row.bin} />,
    sort: 'bin',
    showDesktop: true,
    showTablet: false,
    showMobile: true,
  },
  {
    label: 'front.сounterparties-table.account.label',
    render: row => <DataColumn title={row.accounts[0]?.iban} />,
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: row => [
      {
        label: 'front.mails-table-actions.view.label',
        onClick: () => goto(pages.handbooks.counterparty(row.id)),
      },
      {
        label: 'front.mails-table-actions.delete.label',
        onClick: async () => {
          const otpCode = await confirmModal<string>(ConfirmationSmsModal, {
            action: CheckSmsActionEnum.CONFIRM_COUNTERPARTY_CHANGE,
          });

          if (!otpCode) return;

          await api.handbooks.deleteCounterParty(row.id, otpCode);
        },
        isReloadable: true,
        confirmMessage: 'front.сounterparty.confirm-delete.label',
        isVisible: allowPrivilegy([Privileges.infoCounterpartiesEdit]),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    label: 'front.mails-table-actions.delete.label',
    action: async (selected: any[]) => {
      const otpCode = await confirmModal<string>(ConfirmationSmsModal, {
        action: CheckSmsActionEnum.CONFIRM_COUNTERPARTY_CHANGE,
      });

      if (!otpCode) return;

      const ids = selected
        .filter(item => isActionAllowed([Privileges.infoCounterpartiesEdit], item.customerId))
        .map(item => item.id);

      await api.handbooks.deleteCounterparties(ids, otpCode);
    },
    isReloadable: true,
    confirmMessage: 'front.сounterparty.confirm-delete.label',
    isVisible: () => allowPrivilegy([Privileges.infoCounterpartiesEdit]),
  },
];

export const CounterpartiesPage = () => (
  <DataTable
    name={TableNamesEnum.CounterpartiesTable}
    columns={columns}
    fetchRows={api.handbooks.getNationalCounterparties}
    actions={actions}
    defaultSort="name"
    defaultOrder={SortOrder.Desc}
    filterComponent={CounterpartiesPageFilter}
    onRowClick={row => goto(pages.handbooks.counterparty(row.id))}
  />
);
