import React from 'react';

import { api } from 'api';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { TokenInfo } from 'api/CertificateService/CipherModule/interfaces';
import { Certificate } from 'api/CertificateService/interfaces';
import { CheckSmsActionEnum } from 'api/enums';
import { CheckSmsOrderResult } from 'api/SmsService';
import { Button } from 'components/buttons/Button';
import { SetPayload } from 'components/forms/ValidatingForm/FormContext';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { AgreementFormResult } from 'pages/utils/components/AgreementFormResult/AgreementFormResult';
import { isExist } from 'utils/isData';

import { CheckPinTokenSignProps, ConfirmationPaymentsPinTokenPayloadInterface } from './interfaces';

export const showConfirmationPaymentPinTokenResult = async (
  signResult: CheckSmsOrderResult[],
  ordersCount?: number,
) => {
  const hasError = isExist(signResult.find(item => item.hasError));

  if (ordersCount > 1 || hasError) {
    const errors = signResult.filter(item => item.hasError);
    const successCount = signResult.length - errors.length;

    return await confirmModal<string>(
      AgreementFormResult,
      {
        errors: errors,
        successCount: successCount,
        ordersCount,
        title: 'front.confirm-modal.result-title.label',
        successCounterLabel: 'front.confirm-modal.result-success-counter.label',
        errorCounterLabel: 'front.confirm-modal.result-error-counter.label',
        tooltipMessage: 'front.confirm-modal.result-tooltip.label',
      },
      650,
    );
  }

  return null;
};

export const getPinTokenPaymentInfo = async <T,>(
  ids: number[],
  setPayload: SetPayload<ConfirmationPaymentsPinTokenPayloadInterface<T>>,
) => {
  const response = await api.sms.confirmationRequest({
    action: CheckSmsActionEnum.CONFIRM_AF_PIN,
    params: { orderIdList: ids },
  });

  setPayload({
    tableRows: response.params,
    dataForSign: response.dataForSign,
  });
};

export const checkPinTokenSign = async ({
  signedString,
  ordersCount,
  resolve,
}: CheckPinTokenSignProps) => {
  const { data: signResult } = await api.sms.checkSmsCodeV2({
    code: signedString,
    action: CheckSmsActionEnum.CONFIRM_AF_PIN,
  });

  await showConfirmationPaymentPinTokenResult(signResult, ordersCount);
  await resolve(true);
};

export const createConnectionToAgent = async (token: TokenInfo): Promise<string> => {
  const ticketUuid = await CipherService.createSession();
  await CipherService.setSessionParams(ticketUuid);

  await CipherService.createSessionContainer({
    ticketUuid,
    keyStorePath: token.activeModePath,
  });

  return ticketUuid;
};

const renderNoConnectionModalButtons = ({ resolve }: PropsWithConfirmModal) => (
  <Button
    onClick={() => resolve(null)}
    color="secondary"
    label="front.table.filter-header-close.label"
  />
);

export const checkHasActiveCertificate = async ({
  filteredCertificates,
  certificatesSN,
  resolve,
}: PropsWithConfirmModal<{
  certificatesSN: string | string[];
  filteredCertificates: Certificate[];
}>) => {
  const activeCertificate = filteredCertificates.filter(cert =>
    certificatesSN.includes(cert.certificateSN),
  );

  if (activeCertificate.length === 0) {
    return await confirmModal(NoConnectionModal, {
      renderButtons: () => renderNoConnectionModalButtons({ resolve }),
      description: 'front.confirm-modal.token-no-valid-keys.label',
    });
  }
};
