import * as React from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import { createSelector } from 'reselect';

import { DemandType } from 'api/DemandsService/enums';
import { OrderActionType, PaymentTypes } from 'api/enums';
import store from 'store';
import { selectIsMulticustomer, selectUser } from 'store/selectors';

import { Privileges } from './privileges';

import './styles.scss';

export interface DisplayBlockProps {
  privileges?: Privileges[];
}

interface DisabledBlockProps {
  disabled?: boolean;
  isInline?: boolean;
  privileges?: Privileges[];
}

export const hasPrivileges = (allowPrivileges: Array<Privileges>, checkAllow = true) => {
  const storeState = store.getState();
  const { userState: user } = storeState;

  if (user) {
    return allowPrivileges.some(privilege =>
      checkAllow ? user.privileges.includes(privilege) : !user.privileges.includes(privilege),
    );
  }
  return !checkAllow;
};

export const allowPrivilegy = (allowPrivileges: Array<Privileges>): boolean => {
  const storeState = store.getState();
  const { userState: user } = storeState;

  if (user) {
    return allowPrivileges.some(privilege => user.privileges.includes(privilege));
  }

  return false;
};

export const deniedPrivilegy = (allowPrivileges: Array<Privileges>): boolean => {
  const storeState = store.getState();
  const { userState: user } = storeState;

  if (user) {
    return !allowPrivileges.some(privilege => user.privileges.includes(privilege));
  }
  return true;
};

export const isActionAllowed = (
  allowPrivileges: Array<Privileges>,
  customerId?: number | string,
): boolean => {
  const storeState = store.getState();
  const { userState: user } = storeState;

  if (user?.customers?.length > 1 && customerId) {
    const hasCustomer = user.customers.some(({ id }) => id === Number(customerId));

    if (hasCustomer) {
      const currentCustomerPrivileges = user.customers.find(({ id }) => id === Number(customerId))
        ?.privileges;

      return allowPrivileges.some(privilege => currentCustomerPrivileges.includes(privilege));
    }
  }

  if (user) {
    return allowPrivileges.some(privilege => user.privileges.includes(privilege));
  }
  return false;
};

export const isDeniedAction = (
  allowPrivilegy: Privileges[],
  customerId: number | string = undefined,
): boolean => {
  return !isActionAllowed(allowPrivilegy, customerId);
};

export const isDeniedActionForCustomers = (
  allowPrivilegy: Privileges[],
  customerIds: number[] | string[] = [],
): boolean => {
  return !customerIds.some(customerId => isActionAllowed(allowPrivilegy, customerId));
};

export const allowSomeDemandAction = (action: OrderActionType) => {
  return (
    allowDemandAction(DemandType.NewCardOrder, action) ||
    allowDemandAction(DemandType.NewPlacementTrancheOrder, action) ||
    allowDemandAction(DemandType.NewContractTermDeposit, action) ||
    allowDemandAction(DemandType.NewDepositGeneralOrder, action) ||
    allowDemandAction(DemandType.NewDepositStandartOrder, action) ||
    allowDemandAction(DemandType.NewCreditTranchOrder, action) ||
    allowDemandAction(DemandType.NewSalaryContract, action) ||
    allowDemandAction(DemandType.SMSNotificationOrder, action) ||
    allowDemandAction(DemandType.CashWithdrawalOrder, action)
  );
};

const ucFirst = (str: string) => str[0].toUpperCase() + str.slice(1).toLowerCase();

const parseAction = (action: OrderActionType): string => {
  let correctAction;
  switch (action) {
    case OrderActionType.VIEW:
    case OrderActionType.PRINT:
      correctAction = OrderActionType.VIEW;
      break;
    case OrderActionType.EDIT:
    case OrderActionType.EXECUTE:
    case OrderActionType.DELETE:
    case OrderActionType.COPY:
    case OrderActionType.REJECT:
    case OrderActionType.REVOKE:
    case OrderActionType.MAKE_REGULAR:
    case OrderActionType.CONFIRM_AF_OTP:
    case OrderActionType.CONFIRM_AF_PIN:
      correctAction = OrderActionType.EDIT;
      break;
    case OrderActionType.SIGN:
      correctAction = OrderActionType.SIGN;
  }
  return ucFirst(correctAction);
};

const getPrivileges = (customerId: number): string[] => {
  const storeState = store.getState();
  const { userState: user } = storeState;
  return customerId ? user.customers.find(p => p.id === customerId)?.privileges : user.privileges;
};

export const allowDemandAction = (
  demandType: DemandType,
  action: OrderActionType,
  customerId?: number,
): boolean => {
  const correctAction = parseAction(action);
  const privileges = getPrivileges(customerId);

  switch (demandType) {
    case DemandType.CurrencyBuy:
    case DemandType.CurrencyConversion:
    case DemandType.CurrencySale:
      return privileges.includes(
        Privileges[`demandCurrencyExchange${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.CardBlockingOrder:
    case DemandType.CardClosingOrder:
    case DemandType.CardReissueOrder:
    case DemandType.NewCardOrder:
    case DemandType.ServiceDescOrder:
      return privileges.includes(
        Privileges[`demandCard${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.DepositChangingOrder:
    case DemandType.NewDepositGeneralOrder:
    case DemandType.NewContractTermDeposit:
    case DemandType.NewPlacementTrancheOrder:
    case DemandType.NewDepositStandartOrder:
    case DemandType.NewDepositIndividualOrder:
    case DemandType.DepositClosingOrder:
    case DemandType.DepositProlongationOrder:
      return privileges.includes(
        Privileges[`demandDeposit${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.CreditFundsOrder:
    case DemandType.CreditProlongationOrder:
    case DemandType.NewCreditTranchOrder:
      return privileges.includes(
        Privileges[`demandCredit${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.NewSalaryContract:
    case DemandType.SalaryEmployeeCard:
    case DemandType.SalaryEmployeeDismissal:
    case DemandType.SalaryEmployeeNew:
    case DemandType.SalaryEmployeeReissue:
      return privileges.includes(
        Privileges[`demandSalary${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.SalarySheet:
      return privileges.includes(
        Privileges[`salarySheet${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.SMSNotificationOrder:
    case DemandType.ChangeSMSNotificationOrder:
      return privileges.includes(
        Privileges[`demandSMS${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.AuthLetter:
      return privileges.includes(
        Privileges[`contactSignedLetter${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.AcquiringNewTerminalOrder:
      return privileges.includes(
        Privileges[`acquiringShops${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.CashPreparationOrder:
    case DemandType.CashTransferOrder:
    case DemandType.CashWithdrawalOrder:
      return privileges.includes(
        Privileges[`demandCash${correctAction}` as keyof typeof Privileges],
      );
    case DemandType.FEAMessage:
      return privileges.includes(
        Privileges[`feamessage${correctAction}` as keyof typeof Privileges],
      );
    default:
      return privileges.includes(Privileges[`demand${correctAction}` as keyof typeof Privileges]);
  }
};

export const isPaymentActionAllowed = (
  orderType: string,
  action: OrderActionType,
  customerId?: number | string,
): boolean => {
  const currentAction = parseAction(action);
  switch (orderType) {
    case PaymentTypes.INTERNAL_TRANSFER:
    case PaymentTypes.INTERNAL_TRANSFER_ESCROU:
      return isActionAllowed(
        [Privileges[`paymentInternal${currentAction}` as keyof typeof Privileges]],
        customerId,
      );
    case PaymentTypes.DOMESTIC_TRANSFER:
    case PaymentTypes.DOMESTIC_TRANSFER_ESCROU:
      return isActionAllowed(
        [Privileges[`paymentDomestic${currentAction}` as keyof typeof Privileges]],
        customerId,
      );
    case PaymentTypes.EXPOSED_ORDER:
    case PaymentTypes.INVOICE_ORDER:
      return isActionAllowed(
        [Privileges[`paymentExposed${currentAction}` as keyof typeof Privileges]],
        customerId,
      );
    case PaymentTypes.FOREIGN_TRANSFER:
    case PaymentTypes.FOREIGN_TRANSFER_ESCROU:
      return isActionAllowed(
        [Privileges[`paymentForeign${currentAction}` as keyof typeof Privileges]],
        customerId,
      );
    default:
      return false;
  }
};

export const AllowDisplayBlock: React.FC<DisplayBlockProps> = ({ children, privileges }) => {
  const userPrivileges = useSelector(createSelector(selectUser(), user => user.privileges));
  if (privileges.some(privilege => userPrivileges.includes(privilege))) {
    return <>{children}</>;
  }
  return null;
};

export const AllowForMultiuser: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const isMultiCustomer = useSelector(selectIsMulticustomer());
  return isMultiCustomer && children;
};

export const DisabledBlock: React.FC<DisabledBlockProps> = ({
  privileges = [],
  isInline = false,
  disabled,
  children,
}) => {
  return disabled || hasPrivileges(privileges, false) ? (
    <div className={classnames('disabled-block', { 'inline-block': isInline })}>{children}</div>
  ) : (
    <>{children}</>
  );
};
