import * as React from 'react';

import { Base64 } from 'js-base64';

import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { getCertificateInfo } from 'api/CertificateService/CipherModule/CipherService';
import { ConnectedTokenOptions, TokenInfo } from 'api/CertificateService/CipherModule/interfaces';
import { UserKeystoreType } from 'api/CertificateService/enums';
import { ActionEnum, OrderActionType } from 'api/enums';
import { withForm } from 'components/forms/withForm';
import { getTranslateAction } from 'pages/Payments/general';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { isAvtorCertificate } from 'pages/Profile/Certificates/utils';
import { WrapperAgreePaymentInfoForm } from 'pages/utils/components/WrapperAgreePaymentInfoForm/WrapperAgreePaymentInfoForm';

import { ConfirmationPinTokenForm } from '../components/ConfimationPinTokenFrom/ConfirmationPinTokenForm';
import { usePinTokenConfirmation } from './hooks/usePinTokenConfirmation';
import { ConfirmationPaymentsPinTokenProps } from './interfaces';
import { checkHasActiveCertificate, checkPinTokenSign, createConnectionToAgent } from './utils';

const KepPinTokenConfirmationPaymentsForm = ({
  certificates,
  ids,
  ordersCount,
  resolve,
}: ConfirmationPaymentsPinTokenProps) => {
  const { progress, error, payload, handleSubmit, fetchToken, getFormData } =
    usePinTokenConfirmation<ConnectedTokenOptions, TokenInfo>({
      ids,
      getAvailableToken: () => CipherService.getConnectedTokenOptions(),
    });

  const onSave = async () => {
    const { token: tokenSerial, password } = getFormData();
    const token = payload.tokenOptions?.find(item => item.value === tokenSerial).content;
    validatePasswordWithException(password, '', 'front.form.pin-code.error');

    const ticketUuid = await createConnectionToAgent(token);

    let tokenCertificate;

    try {
      tokenCertificate = await getCertificateInfo(ticketUuid, password, ActionEnum.ConfirmAfPin);
    } catch (error: any) {
      return await checkPinTokenSign({
        signedString: '',
        resolve,
        ordersCount,
      });
    }

    const certificatesSN = tokenCertificate.certificateInfo.certificateSerialNumber?.value ?? '';

    const filteredCertificates = certificates.filter(
      cert => !isAvtorCertificate(cert) && cert.userKeystoreType === UserKeystoreType.HardWired,
    );

    await checkHasActiveCertificate({ filteredCertificates, certificatesSN, resolve });

    const signedString = await CipherService.sign(ticketUuid, password, [
      Base64.toBase64(payload.dataForSign),
    ]);

    await CipherService.closeSession(ticketUuid);

    await checkPinTokenSign({
      signedString: signedString[0],
      resolve,
      ordersCount,
    });
  };

  return (
    <WrapperAgreePaymentInfoForm
      title={getTranslateAction(OrderActionType.CONFIRM_AF_PIN)}
      confirmButtonLabel="front.confirm-modal.confirm.label"
      progress={progress}
      error={error}
      handleSubmit={e => handleSubmit(onSave, e)}
      tableRows={payload?.tableRows}
      resolve={resolve}
    >
      <ConfirmationPinTokenForm
        tokenOptions={payload?.tokenOptions}
        fetchToken={fetchToken}
        isCipher
      />
    </WrapperAgreePaymentInfoForm>
  );
};

export const KepPinTokenConfirmationPaymentsModal = withForm(KepPinTokenConfirmationPaymentsForm);
