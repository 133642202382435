import { api } from 'api';
import { SignType } from 'components/Document/OrderControls';
import { noAccessToConfirmationModal } from 'pages/utils/components/NoAccessToConfirmModal';
import { ConfirmationFactoryTypeEnum } from 'pages/utils/enums';

export const toUniqueArray = <T>(arr: T[]): T[] => [...new Set(arr)];

const hasConfirmationFactor = (factors: SignType[]) =>
  factors.length && factors[0].includes(ConfirmationFactoryTypeEnum.Contact);

export const checkHasConfirmationFactor = async (ids: number[]): Promise<boolean> => {
  const checkConfirmationFactor = await api.payments.canSigningOrders(ids);

  const hasNoAccessToConfirmation =
    !checkConfirmationFactor.length || !hasConfirmationFactor(checkConfirmationFactor[0].factors);

  if (hasNoAccessToConfirmation) {
    return await noAccessToConfirmationModal();
  }

  return true;
};
