import * as React from 'react';

import { Button } from 'components/buttons/Button';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

const NoAccessToConfirmationModal = ({ resolve }: PropsWithConfirmModal) => (
  <div className="confirm-modal">
    <h2 className="confirm-modal__header-text">{translate('front.dear-customer.label')}</h2>
    <h3 className="confirm-modal__message">
      {translate('front.confirm-modal.no-access-to-confirmation.label')}
    </h3>
    <div className="confirm-modal__footer">
      <Button onClick={() => resolve(false)} color="primary" size="sm">
        {translate('front.account-statements-modal.close.label')}
      </Button>
    </div>
  </div>
);

export const noAccessToConfirmationModal = async () =>
  await confirmModal<boolean>(NoAccessToConfirmationModal);
