import React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import { CheckSmsActionEnum, OrderActionType } from 'api/enums';
import { SmsField } from 'components/forms/SmsField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { getTranslateAction } from 'pages/Payments/general';
import { AgreementFormResult } from 'pages/utils/components/AgreementFormResult/AgreementFormResult';
import { SmsModalCounter } from 'pages/utils/components/SmsModalCounter/SmsModalCounter';
import { WrapperAgreePaymentInfoForm } from 'pages/utils/components/WrapperAgreePaymentInfoForm/WrapperAgreePaymentInfoForm';
import { SmsModalFormFieldsEnum } from 'pages/utils/enums';
import {
  SmsModalFormPayloadInterface,
  SmsModalFormProps,
  SmsModalFormStateInterface,
} from 'pages/utils/interfaces';
import { isExist } from 'utils/isData';

const SmsConfirmationPaymentsForm = ({ ordersCount, ids, resolve }: SmsModalFormProps) => {
  const sendSms = async () => {
    const response = await api.sms.confirmationRequest({
      action: CheckSmsActionEnum.CONFIRM_AF_OTP,
      params: { orderIdList: ids },
    });

    setPayload({
      smsLength: response.smsLength,
      tableRows: response.params,
    });
  };

  const { progress, error, handleSubmit, setPayload, payload, initializing } = useForm<
    SmsModalFormStateInterface,
    SmsModalFormPayloadInterface
  >(sendSms);

  const confirmationSms = async (code: string) => {
    const { data: signResult } = await api.sms.checkSmsCodeV2({
      code,
      action: CheckSmsActionEnum.CONFIRM_AF_OTP,
    });

    const hasError = isExist(signResult.find(item => item.hasError));

    if (ordersCount > 1 || hasError) {
      const errors = signResult.filter(item => item.hasError);
      const successCount = signResult.length - errors.length;

      await confirmModal<string>(
        AgreementFormResult,
        {
          errors: errors,
          successCount: successCount,
          ordersCount,
          title: 'front.confirm-modal.result-title.label',
          successCounterLabel: 'front.confirm-modal.result-success-counter.label',
          errorCounterLabel: 'front.confirm-modal.result-error-counter.label',
          tooltipMessage: 'front.confirm-modal.result-tooltip.label',
        },
        650,
      );
    }
  };

  const onSign = async (data: SmsModalFormStateInterface) => {
    await confirmationSms(data.code);
    await resolve(true);
  };

  const { smsLength, tableRows } = payload;

  return (
    <WrapperAgreePaymentInfoForm
      title={getTranslateAction(OrderActionType.CONFIRM_AF_OTP)}
      description="front.sms-modal.sms-sent-to-phone.label"
      confirmButtonLabel="front.confirm-modal.confirm.label"
      resolve={resolve}
      tableRows={tableRows}
      error={error}
      handleSubmit={e => handleSubmit(onSign, e)}
      progress={progress}
    >
      <Col>
        <SmsField
          label="front.login-form.sms.label"
          name={SmsModalFormFieldsEnum.Code}
          length={smsLength}
          autoFocus
        />
      </Col>
      <SmsModalCounter initializing={initializing} sendSms={sendSms} payload={payload} />
    </WrapperAgreePaymentInfoForm>
  );
};

export const SmsConfirmationPaymentsModal = withForm(SmsConfirmationPaymentsForm);
