export enum DictionaryKeyCode {
  AuthLetterTopic = 'AuthLetterTopic',
}

export enum TransactionHistoryStatus {
  EXECUTED = 'EXECUTED',
  INFO = 'INFO',
  INTERNAL = 'INTERNAL',
}

export enum TransactionHistoryEnum {
  ALL = 'all',
  IN = 'in',
  OUT = 'out',
}

export enum PaymentTypes {
  INTERNAL_TRANSFER = 'InternalTransfer',
  DOMESTIC_TRANSFER = 'DomesticTransfer',
  FOREIGN_TRANSFER = 'ForeignTransfer',
  FOREIGN_TRANSFER_ESCROU = 'ForeignTransferESCROU',
  EXPOSED_ORDER = 'ExposedOrder',
  INVOICE_ORDER = 'InvoiceOrder',
  SALARY_SHEET = 'SalarySheet',
  INTERNAL_TRANSFER_ESCROU = 'InternalTransferESCROU',
  DOMESTIC_TRANSFER_ESCROU = 'DomesticTransferESCROU',
}

export enum OrderActionType {
  EXECUTE = 'EXECUTE',
  REJECT = 'REJECT',
  REVOKE = 'REVOKE',
  DELETE = 'DELETE',
  SIGN = 'SIGN',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  COPY = 'COPY',
  PRINT = 'PRINT',
  MAKE_REGULAR = 'MAKE_REGULAR',
  EXPORT_ATTACHMENTS = 'EXPORT-ATTACHMENTS',
  CONFIRM_AF_OTP = 'CONFIRM_AF_OTP',
  CONFIRM_AF_PIN = 'CONFIRM_AF_PIN',
}

export enum VatType {
  WITHOUT_VAT = 'WITHOUT_VAT',
  INCLUDE_VAT20 = 'INCLUDE_VAT20',
  EMPTY = 'EMPTY',
  TAXES_PAYMENT = 'TAXES_PAYMENT',
}

export enum PaymentStatuses {
  All = 'all',
  Sign = 'Sign',
  SentToBank = 'SentToBank',
  Rejected = 'Rejected',
  Executed = 'Executed',
}

export enum AccountType {
  CURRENT = 'Current',
  SPECIAL = 'SPECIAL',
  CARD = 'Card',
  DEPOSIT = 'Deposit',
  CREDIT = 'Credit',
  CREDIT_PERCENT = 'CREDIT_PERCENT',
  INNER = 'Inner',
}

export enum OrderState {
  Draft = 'Draft',
  Deleted = 'Deleted',
  PartiallySigned = 'PartiallySigned',
  Signed = 'Signed',
  Ready = 'Ready',
  SentToBank = 'SentToBank',
  Executed = 'Executed',
  Rejected = 'Rejected',
  Sent = 'Sent',
  Revoked = 'Revoked',
  InWork = 'InWork',
  Unknown = 'Unknown',
  SendingToBank = 'SendingToBank',
  InProcess = 'InProcess',
  ReceivedByBank = 'ReceivedByBank',
  NeedSms = 'NeedSms',
  Accepted = 'Accepted',
  RejectedByClient = 'RejectedByClient',
  RetainedByBank = 'RetainedByBank',
  OnExecution = 'OnExecution',
  OnPartialExecution = 'OnPartialExecution',
  PartiallyExecuted = 'PartiallyExecuted',
  NeedConfirmation = 'NeedConfirmation',
  AntiFraudVerification = 'AntiFraudVerification',
}

export enum CheckSmsActionEnum {
  ADD_CERTIFICATE = 'ADD_CERTIFICATE',
  CONFIRM_COUNTERPARTY_CHANGE = 'CONFIRM_COUNTERPARTY_CHANGE',
  CONFIRM_TEMPLATE_CREATE = 'CONFIRM_TEMPLATE_CREATE',
  CONFIRM_TEMPLATE_UPDATE = 'CONFIRM_TEMPLATE_UPDATE',
  CONFIRM_TEMPLATE_DELETE = 'CONFIRM_TEMPLATE_DELETE',
  CONFIRM_AF_OTP = 'CONFIRM_AF_OTP',
  CONFIRM_AF_PIN = 'CONFIRM_AF_PIN',
}

export enum ActionEnum {
  CreateCertificate = 'CreateCertificate',
  AddCertificate = 'AddCertificate',
  SignAuthor = 'SignAuthor',
  SignCipher = 'SignCipher',
  LoginWithAuthor = 'LoginWithAuthor',
  LoginWithCipher = 'LoginWithCipher',
  ConfirmAfOtp = 'CONFIRM_AF_OTP',
  ConfirmAfPin = 'CONFIRM_AF_PIN',
}

export enum ConstantEnum {
  AllowFileKey = 'ALLOW_FILE_KEY',
  AllowTokenKey = 'ALLOW_TOKEN_KEY',
  IsMultiCustomer = 'IS_MULTI_CUSTOMER',
  FileSize = 'FILE_SIZE',
  FileType = 'FILE_TYPE',
  AllowedDaysForReissue = 'ALLOWED_DAYS_FOR_REISSUE',
  SmsValidPeriod = 'SMS_VALID_PERIOD',
  AllowedPasswordSymbols = 'ALLOWED_PWD_SYMBOLS',
  MinPasswordLength = 'MIN_PWD_LENGTH',
  MaxPasswordLength = 'MAX_PWD_LENGTH',
  MobileOperatorsCodes = 'MOBILE_OPERATORS_CODES',
  MinLoginLength = 'MIN_LOGIN_LENGTH',
  MaxLoginLength = 'MAX_LOGIN_LENGTH',
  AllowedLoginSymbols = 'ALLOWED_LOGIN_SYMBOLS',
  CompanyPersonDaysToExpire = 'COMPANY_PERSON_EXPIRE_NOTIFICATION_TERM',
  AllowedDaysForCashOrderWithdraw = 'ALLOWED_DAYS_FOR_CASH_ORDER_WITHDRAW',
  AllowedDaysForCashOrderTransfer = 'ALLOWED_DAYS_FOR_CASH_ORDER_TRANSFER',
  AllowedDaysForCashOrderDemands = 'ALLOWED_DAYS_FOR_CASH_ORDER_DEMANDS',
  CertificateCaSerials = 'CERTIFICATE_CA_SERIALS',
  CipherTokenCaId = 'CIPHER_TOKEN_CA_ID',
  IpRestriction = 'IP_RESTRICTION',
  HasRole = 'HAS_ROLE',
  BankCodesForBudgetPayments = 'BANK_CODES_FOR_BUDGET_PAYMENTS',
  LinkToUserManual = 'LINK_TO_USER_MANUAL',
}

export enum PersonStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
}

export enum EmployeeStatus {
  ALL = '',
  ACTIVE = 'ACTIVE',
  FIRED = 'FIRED',
  NEW = 'NEW',
}

export enum OrderKind {
  TEMPLATE = 'TEMPLATE',
}
