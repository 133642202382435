import React from 'react';
import { useLocation } from 'react-router';

import { api } from 'api';
import { parseUrlParams } from 'api/backend';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderKind, OrderState, VatType } from 'api/enums';
import { Account } from 'api/interfaces';
import { Order, OrderAction } from 'api/OrderService';
import { Option } from 'api/Service';
import { useOrder } from 'components/Document/useOrder';
import { generatedByInvoice, getOrderDate } from 'components/Document/utils';
import { FetchOrderCb, OnSaveCreator, withOrder } from 'components/Document/withOrder';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import { TabBar } from 'components/tabs/TabBar';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isPaymentActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { OrderParams, pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import { DebtQueryInterface } from 'pages/Login/CustomerSelectModal/DebtModal/interface';
import { getTranslateAction } from 'pages/Payments/general';
import { PaymentHistoryPage } from 'pages/Payments/History/PaymentHistoryPage';
import {
  DomesticSourceFieldsEnum,
  InternalSourceFieldsEnum,
} from 'pages/Payments/InternalPayment/enums';
import {
  prepareFieldsInternalPayment,
  prepareRequestDataInternalPayment,
} from 'pages/Payments/InternalPayment/helpers';
import { DetailProps, Payload } from 'pages/Payments/InternalPayment/interface';
import { Accounts } from 'pages/Profile/Accounts/AccountsPage';
import { pinTokenConfirmationPayments } from 'pages/utils/ConfirmationPayments/PinTokenConfirmationPaymentsModal/general';
import { smsConfirmationPayments } from 'pages/utils/ConfirmationPayments/SmsConfirmationPaymentsModal/general';
import { isExist } from 'utils/isData';

import { DomesticTransferDetails } from './DomesticTransferDetails';
import { PaymentOrderFields as Fields } from './interface';
import { InternalTransferDetails } from './InternalTransferDetails';
import { getAccountValue, hasSigningRules } from './utils';

const expandedTabOptionsFoOrderType = (options: Option[]) =>
  options.reduce((acc, option): Option[] => {
    if (option.value === OrderType.DomesticTransfer) {
      acc = [...acc, option, { label: option.label, value: OrderType.DomesticTransferESCROU }];
    }

    if (option.value === OrderType.InternalTransfer) {
      acc = [...acc, option, { label: option.label, value: OrderType.InternalTransferESCROU }];
    }
    return acc;
  }, []);

const paymentTabOptions: Option<undefined, OrderType>[] = [
  {
    label: 'front.internal-payment-page.order-external-type.label',
    value: OrderType.DomesticTransfer,
  },
  {
    label: 'front.internal-payment-page.order-internal-type.label',
    value: OrderType.InternalTransfer,
  },
];

const getPaymentTabsOptions = (customerId: number | string): Option<undefined, OrderType>[] =>
  paymentTabOptions.filter(opt =>
    isPaymentActionAllowed(opt.value, OrderActionType.EDIT, customerId),
  );

const actions: OrderAction[] = [
  {
    label: getTranslateAction(OrderActionType.REJECT),
    name: OrderActionType.REJECT,
    type: 'dropdown',
    onClick: async order => await api.payments.executeAction(order.id, OrderActionType.REJECT),
    reloadable: true,
  },
  {
    name: OrderActionType.REVOKE,
    label: getTranslateAction(OrderActionType.REVOKE),
    type: 'dropdown',
    onClick: async order => {
      await api.payments.withdrawOrders([order.id]);
      await confirmationModal(
        translate('front.working-documents-table.actions-revoke-sent.label'),
        true,
        false,
      );
    },
    confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
    closable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    name: OrderActionType.DELETE,
    type: 'dropdown',
    onClick: async order => {
      await api.payments.deleteOrder(order.id);
      goto(pages.payments);
    },
    privileges: [Privileges.paymentDomesticEdit],
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
  },
  {
    label: getTranslateAction(OrderActionType.SIGN),
    name: OrderActionType.SIGN,
    type: 'signButton',
    reloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.COPY),
    name: OrderActionType.COPY,
    type: 'dropdown',
    onClick: order => {
      goto(pages.internalPayments.tabs.details('new', { copyFrom: `${order.id}` }));
    },
    privileges: [Privileges.paymentDomesticEdit],
  },
  {
    label: 'front.working-documents-table.actions-schedule.label',
    name: OrderActionType.MAKE_REGULAR,
    type: 'dropdown',
    onClick: order => {
      goto(
        pages.regularPayment.tabs.schedule(order.id, 'new', {
          type: order.type as OrderType,
        }),
      );
    },
    privileges: [Privileges.paymentRegularEdit],
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    name: OrderActionType.PRINT,
    type: 'dropdown',
    onClick: order => api.payments.getOrderPdf(order.id),
  },
  {
    label: getTranslateAction(OrderActionType.CONFIRM_AF_OTP),
    name: OrderActionType.CONFIRM_AF_OTP,
    onClick: async order => await smsConfirmationPayments([order.id]),
    privileges: [Privileges.paymentDomesticEdit],
    type: 'dropdown',
    closable: true,
  },
  {
    label: getTranslateAction(OrderActionType.CONFIRM_AF_PIN),
    name: OrderActionType.CONFIRM_AF_PIN,
    onClick: async order => await pinTokenConfirmationPayments([order.id], [order.customerId]),
    privileges: [Privileges.paymentDomesticEdit],
    type: 'dropdown',
    closable: true,
  },
];

const fetchSourceFiledAndParams = async (
  orderType: string,
  customerId?: number,
  generateNumber?: boolean,
  withDefaultAccount?: boolean,
  params?: OrderParams<DebtQueryInterface>,
) => {
  const isDomestic =
    orderType === OrderType.DomesticTransfer || orderType === OrderType.DomesticTransferESCROU;

  const sourceFields = isDomestic
    ? await api.payments.getDomesticTransferSourceFields(
        Object.values(DomesticSourceFieldsEnum),
        generateNumber,
        customerId,
      )
    : await api.payments.getInternalTransferSourceFields(
        Object.values(InternalSourceFieldsEnum),
        generateNumber,
        customerId,
        params,
      );

  const { transferDefaultAccount, paymentDefaultAccount } = withDefaultAccount
    ? await api.user.getDefaultAccounts({
        customerId,
        code: [Accounts.ACC_TRANSFER_DEFAULT_ACCOUNT, Accounts.PAYMENT_ORDER_DEFAULT_ACCOUNT],
      })
    : { transferDefaultAccount: null, paymentDefaultAccount: null };

  return {
    sourceFields,
    defaultPayerAccountId: isDomestic ? paymentDefaultAccount : transferDefaultAccount,
  };
};

const getAccountCurrency = (
  accountId: number | string,
  options: Option<Account>[] = [],
): string => {
  return accountId
    ? options.find(acc => Number(acc.value) === Number(accountId))?.content?.currency
    : null;
};

const getPaymentTitle = (type: string | null): string => {
  if (!type) {
    return translate('front.internal-payment-page.title.label');
  }

  return type === OrderType.InternalTransfer || type === OrderType.InternalTransferESCROU
    ? translate('front.internal-payment-page.order-internal-type.title.label')
    : translate('front.internal-payment-page.order-external-type.title.label');
};

const fetchOrder: FetchOrderCb<Partial<Fields>, Payload, DebtQueryInterface> = async (
  { customerId: defaultCustomerId, routeParams, queryParams },
  { setPayload },
): Promise<Order<Partial<Fields>>> => {
  if (routeParams.id === NEW) {
    const sourceOrder = queryParams.copyFrom
      ? await api.order.getNewOrderModel<DemandResponse>(queryParams.copyFrom)
      : null;

    const type = sourceOrder
      ? sourceOrder.base.orderType
      : queryParams.type || OrderType.DomesticTransfer;

    const customerId = sourceOrder
      ? sourceOrder.base.customerId
      : queryParams.customerId || defaultCustomerId;

    const label = getPaymentTitle(sourceOrder ? type : null);

    const { defaultPayerAccountId, sourceFields } = await fetchSourceFiledAndParams(
      type,
      customerId,
      true,
      true,
      queryParams,
    );
    const {
      accountsOptions: payerAccountOptions,
      creditAccountsOptions: receiverAccountOptions,
      receiverOptions,
      budgetsOptions,
      localBanksOptions,
      countriesOptions,
      purposes,
    } = sourceFields;

    const detailFields = sourceOrder
      ? prepareFieldsInternalPayment({
          doc: sourceOrder,
          isDraft: true,
          payerAccounts: payerAccountOptions,
          receiverAccounts: receiverAccountOptions,
          isNew: true,
        })
      : null;

    const payerAccountNumberFromParams = queryParams.payerAccount;
    const receiverAccountNumberFromParams = queryParams.receiverAccount;

    let payerAccount = getAccountValue(
      payerAccountNumberFromParams || defaultPayerAccountId,
      payerAccountOptions,
    );

    const isoCode =
      sourceOrder?.financial.isoCode ?? getAccountCurrency(payerAccount, payerAccountOptions);

    const { orderNumber, orderDate, ...fields } = sourceFields.formFields;

    setPayload({
      payerAccountOptions,
      receiverAccountOptions,
      receiverOptions, // for Domestic transfer only
      budgetsOptions, // for Domestic transfer only
      localBanksOptions, // for Domestic transfer only
      countriesOptions, // for Domestic transfer only
      purposes, // for Domestic transfer only
      isoCode,
      allPayerAccountOptions: payerAccountOptions,
      allReceiverAccountOptions: receiverAccountOptions,
    });

    let receiverAccount: string = null;
    if (receiverAccountNumberFromParams) {
      receiverAccount = getAccountValue(receiverAccountNumberFromParams, receiverAccountOptions);

      const receiverAccountCurrency = getAccountCurrency(
        receiverAccountNumberFromParams,
        receiverAccountOptions,
      );

      // prefilter payer accounts if receiver account is predefined
      const isSamePayerAccounts = Number(payerAccount) === Number(defaultPayerAccountId);

      const isDifferentCurrencies =
        isExist(receiverAccountCurrency) && isoCode !== receiverAccountCurrency;

      if (isSamePayerAccounts && isDifferentCurrencies) {
        payerAccount = null;

        setPayload({
          receiverAccountOptions,
          receiverOptions, // for Domestic transfer only
          budgetsOptions, // for Domestic transfer only
          localBanksOptions, // for Domestic transfer only
          countriesOptions, // for Domestic transfer only
          purposes, // for Domestic transfer only
          isoCode,
          payerAccountOptions: payerAccountOptions.filter(
            item => item?.content.currency === receiverAccountCurrency,
          ),
          allPayerAccountOptions: payerAccountOptions,
          allReceiverAccountOptions: receiverAccountOptions,
        });
      }
    } else if (type === OrderType.DomesticTransfer || type === OrderType.DomesticTransferESCROU) {
      receiverAccount = 'UA';
    }

    const checkIsAvailableSign = await hasSigningRules(
      payerAccountOptions,
      receiverAccountOptions,
      type as OrderType,
      customerId ?? defaultCustomerId,
      OrderState.Draft,
    );

    return {
      label,
      customerId,
      id: null,
      checkIsAvailableSign,
      date: format.parseDate(orderDate),
      number: orderNumber,
      state: null,
      stateTranslate: null,
      type: type || OrderType.DomesticTransfer,
      actions: [],
      detailFields: detailFields || {
        ...fields,
        payerAccount,
        receiverAccount,
        isoCode,
        vatType: VatType.INCLUDE_VAT20,
        amount: queryParams?.amount,
      },
    };
  }

  const doc = await api.order.getNewOrderModel<DemandResponse>(routeParams.id);
  const orderType: OrderType = doc.base.orderType as OrderType;
  const isoCode = doc.financial.isoCode;
  const isDraft = doc.base.orderState === OrderState.Draft;

  const { sourceFields } = await fetchSourceFiledAndParams(orderType, doc.base.customerId);

  const {
    accountsOptions: payerAccountOptions,
    creditAccountsOptions: receiverAccountOptions,
    receiverOptions,
    budgetsOptions,
    localBanksOptions,
    countriesOptions,
    purposes,
  } = sourceFields;

  setPayload({
    receiverAccountOptions,
    payerAccountOptions,
    budgetsOptions,
    localBanksOptions,
    countriesOptions,
    purposes,
    receiverOptions,
    isoCode,
    allReceiverAccountOptions: receiverAccountOptions,
    allPayerAccountOptions: payerAccountOptions,
  });

  const detailFields = prepareFieldsInternalPayment({
    doc,
    isDraft,
    isNew: doc.base.orderKind === OrderKind.TEMPLATE,
    payerAccounts: payerAccountOptions,
    receiverAccounts: receiverAccountOptions,
  });

  const checkIsAvailableSign = await hasSigningRules(
    payerAccountOptions,
    receiverAccountOptions,
    orderType,
    doc.base.customerId,
    doc.base.orderState,
  );

  return {
    detailFields,
    customerId: doc.base.customerId,
    id: doc.id,
    date: getOrderDate(doc.base),
    valueDate: doc.financial.valueDate
      ? (format.parseDate(doc.financial.valueDate) as unknown as string)
      : null,
    linkedOrderId: doc.base.linkedOrderId,
    linkedOrderType: doc.base.linkedOrderType,
    orderKind: doc.base.orderKind,
    label: getPaymentTitle(doc.base.orderType),
    state: doc.base.orderState,
    stateTranslate: doc.base.orderState,
    number: doc.base.orderNumber,
    type: doc.base.orderType,
    actions: actions.filter(
      action =>
        doc.actions.some(({ actionName, confirmation }) => {
          if (confirmation) {
            action.payload = { confirmation };
          }
          return (
            actionName === action.name ||
            (action.name === OrderActionType.SIGN && checkIsAvailableSign)
          );
        }) && isPaymentActionAllowed(doc?.base?.orderType, action?.name, doc?.base?.customerId),
    ),
  };
};

const createOnSave: OnSaveCreator<Fields, Payload> = (order, pld) => async (formData, options) => {
  if (order.id && options?.templateName) {
    return await api.payments.createOrderTemplate(order.id, options?.templateName);
  }

  const orderRequest = await prepareRequestDataInternalPayment({
    order,
    pld,
    formData,
    options,
  });

  if (formData?.savePaymentPurpose) {
    await api.payments.createPaymentPurpose({ purpose: formData.purpose });
  }

  if (!order.id) {
    return await api.payments.createPayment(orderRequest);
  }

  await api.payments.updatePayment(order.id, orderRequest);
  return order.id;
};

const InternalPaymentPageComponent: React.FC = () => {
  const { order, onChangeNewOrderType, form, clearOrder } = useOrder<Fields, Payload>();
  const { copyFrom } = parseUrlParams(useLocation().search);
  const isDomesticTransfer =
    order?.type === OrderType.DomesticTransfer || order?.type === OrderType.DomesticTransferESCROU;

  const tabOptions: Option<undefined, OrderType>[] = order?.customerId
    ? getPaymentTabsOptions(order?.customerId)
    : [];

  const needRedirect = !expandedTabOptionsFoOrderType(tabOptions).some(
    o => o.value === order?.type,
  );

  const isNew = !order || !order.id;

  // Redirect on allow order type if customer not privilege for current order ONLY INTERNAL PAYMENT;
  React.useEffect(() => {
    if (order && needRedirect && isNew) {
      const allowOrderType = OrderType[tabOptions[0]?.value];
      allowOrderType && onChangeTransferType(allowOrderType);
    }
  }, [order?.customerId]);

  const onChangeTransferType = async (type: OrderType) => {
    form.clearData();
    clearOrder();
    goto(pages.internalPayments.tabs.details('new', { type: OrderType[type] }));
    await onChangeNewOrderType(type);
  };

  if (isNew && copyFrom) {
    return (
      <NavTabs>
        <NavTab
          title={translate('front.internal-payment-page.order-detail-tab.label')}
          path={pages.internalPayments.tabs.details(':id')}
        >
          {isDomesticTransfer ? <DomesticTransferDetails /> : <InternalTransferDetails />}
        </NavTab>
      </NavTabs>
    );
  }

  if (isNew) {
    return (
      <>
        <TabBar
          value={order?.type || OrderType.InternalTransfer}
          options={tabOptions}
          onChange={onChangeTransferType}
        />
        {isDomesticTransfer ? (
          <DomesticTransferDetails allowSavePurpose />
        ) : (
          <InternalTransferDetails allowSavePurpose />
        )}
      </>
    );
  }

  return (
    <NavTabs>
      <NavTab
        title={translate('front.internal-payment-page.order-detail-tab.label')}
        path={pages.internalPayments.tabs.details(':id')}
      >
        {isDomesticTransfer ? <DomesticTransferDetails /> : <InternalTransferDetails />}
      </NavTab>
      <NavTab
        title={translate('front.internal-payment-page.order-history-tab.label')}
        path={pages.internalPayments.tabs.history(':id')}
      >
        <PaymentHistoryPage />
      </NavTab>
    </NavTabs>
  );
};

const getPrivilege = (type: OrderType): Privileges =>
  type === OrderType.DomesticTransfer || type === OrderType.DomesticTransferESCROU
    ? Privileges.paymentDomesticView
    : Privileges.paymentInternalView;

export const InternalPaymentPage = withOrder<
  Partial<Fields>,
  Payload,
  DetailProps
  // @ts-ignore
>(({ order }) => ({
  fetchOrder,
  createOnSave,
  allowTemplates: true,
  allowState: true,
  allowDetails: true,
  showSaveButton: !generatedByInvoice(order),
  allowValueDate:
    order?.type === OrderType.DomesticTransfer || order?.type === OrderType.DomesticTransferESCROU,
  allowSave:
    order &&
    isPaymentActionAllowed(order.type as OrderType, OrderActionType.EDIT, order.customerId),
  allowSign:
    order &&
    order.checkIsAvailableSign &&
    isPaymentActionAllowed(order.type as OrderType, OrderActionType.SIGN, order.customerId),
  afterSubmit: (order, options = {}) => {
    if (!options.templateName) {
      goto(pages.payments);
    }
  },
  disableButtons: true,
  getCustomerFilteringPrivilege: getPrivilege,
}))(InternalPaymentPageComponent);
