import { Option } from 'api/Service';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import {
  ConfirmationPaymentsPinTokeFieldsInterface,
  ConfirmationPaymentsPinTokenPayloadInterface,
} from 'pages/utils/ConfirmationPayments/PinTokenConfirmationPaymentsModal/interfaces';
import { getPinTokenPaymentInfo } from 'pages/utils/ConfirmationPayments/PinTokenConfirmationPaymentsModal/utils';

interface usePinTokenConfirmationProps<T> {
  getAvailableToken: () => Promise<T[]>;
  ids: number[];
}

export const usePinTokenConfirmation = <T extends Option, P>({
  ids,
  getAvailableToken,
}: usePinTokenConfirmationProps<T>) => {
  const { progress, error, payload, setPayload, updateData, getFormData, handleSubmit } = useForm<
    ConfirmationPaymentsPinTokeFieldsInterface,
    ConfirmationPaymentsPinTokenPayloadInterface<P>
  >(async () => {
    await fetchToken();
    await getPinTokenPaymentInfo(ids, setPayload);
  });

  const fetchToken = async () => {
    const tokenOptions = await getAvailableToken();
    const token = tokenOptions[0]?.value;

    updateData({ token });
    setPayload({ tokenOptions });
  };

  return {
    progress,
    error,
    payload,
    handleSubmit,
    fetchToken,
    getFormData,
  };
};
