import * as React from 'react';
import { Col, Container, Row } from 'react-grid';
import reactHtmlParser from 'react-html-parser';

import { OrderSignSmsRows } from 'api/interfaces';
import { Button } from 'components/buttons/Button';
import { FormError } from 'components/forms/FormError';
import { DefaultForm } from 'components/forms/formParts';
import { translate } from 'i18n/translator';
import { AgreePaymentInfoTable } from 'pages/utils/components/AgreePaymentInfoTable';

import './WrapperAgreePaymentInfoForm.scss';

interface WrapperAgreePaymentInfoFormProps {
  confirmButtonLabel: string;
  error: string;
  handleSubmit: (e: any) => Promise<boolean>;
  progress: boolean;
  resolve: () => void;
  tableRows: OrderSignSmsRows;
  title: string;
  description?: string;
}

export const WrapperAgreePaymentInfoForm = ({
  title,
  description,
  confirmButtonLabel,
  tableRows,
  progress,
  error,
  children,
  handleSubmit,
  resolve,
}: React.PropsWithChildren<WrapperAgreePaymentInfoFormProps>) => (
  <div className="wrapper-form-info">
    <div className="wrapper-form-info__title">{translate(title)}</div>
    {tableRows && AgreePaymentInfoTable(tableRows)}
    {description && <div className="wrapper-form-info__desc">{translate(description)}</div>}
    <DefaultForm disabled={progress} className="wrapper-form-info__form">
      <Container>
        <Row>
          <Col>{error && <FormError>{reactHtmlParser(error)}</FormError>}</Col>
          <Row>{children}</Row>
        </Row>
        <Row className="wrapper-form-info__footer">
          <Col sm={6}>
            <Button
              onClick={e => handleSubmit(e)}
              size="md"
              progress={progress}
              type="submit"
              className="wrapper-form-info__footer__btn"
              label={confirmButtonLabel}
            />
          </Col>
          <Col sm={6}>
            <Button
              onClick={() => resolve()}
              color="secondary"
              size="md"
              className="wrapper-form-info__footer__btn"
              label={'front.sms-modal.sms-cancel-button.label'}
            />
          </Col>
        </Row>
      </Container>
    </DefaultForm>
  </div>
);
