import * as React from 'react';
import { Col } from 'react-grid';

import { Option } from 'api/Service';
import { PasswordField } from 'components/forms/inputs/PasswordField';
import { SelectField } from 'components/forms/inputs/SelectField';
import { translate } from 'i18n/translator';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';

import './ConfirmationPinTokenForm.scss';

interface ConfirmationFormComponentProps {
  fetchToken: () => Promise<void>;
  tokenOptions: Option[];
  isCipher?: boolean;
}

export const ConfirmationPinTokenForm = ({
  fetchToken,
  tokenOptions,
  isCipher = false,
}: ConfirmationFormComponentProps) => {
  const [isSearchTokens, setIsSearchTokens] = React.useState(false);

  const onSearchToken = async () => {
    setIsSearchTokens(true);
    await fetchToken();
    setIsSearchTokens(false);
  };

  return (
    <>
      <Col sm={10}>
        <div className="signature-form__field">
          <SelectField
            name="token"
            label={translate('front.cert-page-add.input-token.select-umca-token.label')}
            options={tokenOptions}
            isSearchable={false}
            required
          />
        </div>
      </Col>
      <Col sm={2}>
        <div className="search-token-authorizing-step">
          <SearchTokenButton onSearchToken={onSearchToken} />
        </div>
      </Col>
      <div className="key-not-found">
        <Col sm={12}>
          <SearchTokenLabel isSearchTokens={isSearchTokens} values={tokenOptions} />
        </Col>
      </div>
      <Col sm={12}>
        <div className="signature-form__field pin-token-field ">
          <PasswordField
            label="front.login-form.certificate.token-pin.label"
            name="password"
            minLength={6}
            maxLength={isCipher ? 20 : 8}
            required
          />
        </div>
      </Col>
    </>
  );
};
