import React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import * as CipherService from 'api/CertificateService/CipherModule/CipherService';
import { ImportCertificateData } from 'api/CertificateService/CipherModule/interfaces';
import { CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import {
  Certificate,
  CloudKepCertificateRequest,
  TokenKepCertificateRequest,
} from 'api/CertificateService/interfaces';
import { CheckSmsActionEnum } from 'api/enums';
import { DataFromWebBank } from 'api/interfaces';
import { SelectField } from 'components/forms/inputs/SelectField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { ConfirmationSmsModal } from 'components/modals/ConfirmationSmsModal';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { Privileges } from 'navigations/privileges';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';
import { confirmIssueCertificateUEP } from 'pages/Profile/Certificates/KepCertificate/Modals/IssueUepKeyModal';
import {
  CreateFetchCerts,
  CreateOnSave,
  FetchOnMount,
  Payload,
} from 'pages/Profile/Certificates/KepCertificate/types';
import { useSearchToken } from 'pages/Profile/Certificates/KepCertificate/useSearchToken';
import {
  certificateVerification,
  confirmIssueCertificateKep,
  defaultStoreOption,
  ERROR_CODES,
  gotoCertificatesPage,
  hasSomeActiveCertificate,
  importCertificates,
} from 'pages/Profile/Certificates/KepCertificate/utils';
import { withKepCertificate } from 'pages/Profile/Certificates/KepCertificate/withKepCertificate';
import { AwaitCertificateModal } from 'pages/Profile/Certificates/Modals/AwaitCertificateModal';
import { AwaitCertificateFormProps } from 'pages/Profile/Certificates/Modals/interfaces';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { parseErrorMessage } from 'pages/Profile/Certificates/utils';
import { ConfirmationIdentificationFormFields } from 'pages/Registration/enums';

import { CertificateFieldNames, KeyKepType, StoreTypeID } from './enums';

const isEmptyInn = (inn: string) => new RegExp(/^[0 ]+$/).test(inn);

const CreateCert = () => {
  const { payload } = useForm<Payload>();
  const { onSearchToken, isSearchTokens } = useSearchToken();

  return (
    <>
      <Col sm={10}>
        <SelectField
          label="front.cert-page-new.input-token.select-keystore.label"
          name={CertificateFieldNames.Store}
          defaultValue={KeyKepType.CLOUD_STORAGE}
          options={payload.storeOptions}
          isSearchable={false}
          required
        />
      </Col>
      <SearchTokenButton onSearchToken={onSearchToken} />
      <SearchTokenLabel values={payload.storeOptions} isSearchTokens={isSearchTokens} />
    </>
  );
};

const fetchOnMount: FetchOnMount = async ({ customer }, { setFields, setPayload }) => {
  setPayload({ storeOptions: defaultStoreOption });
  setFields({ customerId: `${customer.id}` });
};

const createFetchCerts: CreateFetchCerts =
  ({ updateData, setPayload }) =>
  async (mode, filter, isSearchToken) => {
    if (isSearchToken) {
      const isAvailable = await CipherService.isCipherAvailable();

      if (isAvailable) {
        const tokenOption = await CipherService.getConnectedTokenOptions();

        const storeOptions = [...defaultStoreOption, ...tokenOption];

        const storeValue =
          storeOptions[StoreTypeID.TOKEN] || storeOptions[StoreTypeID.CLOUD_STORAGE];

        updateData({ store: storeValue.value });
        setPayload({ storeOptions: storeOptions });
        return;
      }

      updateData({ store: defaultStoreOption[StoreTypeID.CLOUD_STORAGE].value });
      setPayload({ storeOptions: defaultStoreOption });

      return await confirmModal(NoConnectionModal);
    }
  };

const createOnSave: CreateOnSave =
  (validatePassword, { payload }) =>
  async formData => {
    const { password, puk, store, customerId } = formData;
    const isTokenMode = store !== KeyKepType.CLOUD_STORAGE;
    const tokenOption = payload.storeOptions.find(option => option.value === store);

    validatePassword();
    let isReissuedCertificate = false;

    const certificates = await api.certificate.getCertificates();
    const currentCustomerCerts =
      certificates.filter(cert => cert.customerId === +customerId) ?? ([] as Certificate[]);

    if (!isTokenMode && hasSomeActiveCertificate(currentCustomerCerts)) {
      await confirmationModal('front-profile.prohibited-certificate.label', false, false);
      return gotoCertificatesPage();
    }

    if (isTokenMode) {
      const { isVerifiedCert, isReissuedCert } = await certificateVerification(
        currentCustomerCerts,
        tokenOption,
        password,
      );

      isReissuedCertificate = isReissuedCert;

      if (!isVerifiedCert) {
        return gotoCertificatesPage();
      }
    }

    let dataProfile: DataFromWebBank;
    let isGenerateUEP = false;

    const confirmPayload = {
      customerId: +customerId,
      keystoreType: isTokenMode ? UserKeystoreType.HardWired : UserKeystoreType.CloudStorage,
      isReissue: isReissuedCertificate,
      keystoreName: isTokenMode ? tokenOption.content.serial : null,
      certificateType: CertificateType.Kep,
    };

    try {
      dataProfile = await confirmIssueCertificateKep(confirmPayload);
    } catch (e: any) {
      const parsedError = JSON.parse(e.message);

      if (ERROR_CODES.includes(parsedError.code)) {
        try {
          const result = await confirmIssueCertificateUEP({
            confirmPayload: { ...confirmPayload, certificateType: CertificateType.Uep },
            message: parseErrorMessage(e),
          });

          if (!result) return;

          isGenerateUEP = result.isGenerateUEP;
          dataProfile = result.dataProfile;
        } catch (e: any) {
          return await confirmationModal(parseErrorMessage(e), false, false);
        }
      } else {
        return await confirmationModal(parseErrorMessage(e), false, false);
      }
    }

    if (!dataProfile) {
      return gotoCertificatesPage();
    }

    const smsCode = await confirmModal<string>(ConfirmationSmsModal, {
      action: CheckSmsActionEnum.ADD_CERTIFICATE,
    });
    if (!smsCode) return gotoCertificatesPage();

    if (isTokenMode) {
      const ticketUuid = await CipherService.createSession();
      const profileId = await CipherService.getKeyProfiles(dataProfile.profileId);

      const { agreementKeyPkcs10Request, signatureKeyPkcs10Request } =
        await CipherService.createCertificate({
          ...dataProfile.confirmedProfile,
          NEW_KEY_TYPE: isGenerateUEP ? 'SIGNATURE' : 'SIGNATURE_AND_AGREEMENT',
          PKCS10_DELIVERY_TYPE: 'RETURN_IN_RESPONSE',
          PROFILE_ID: profileId,
          [ConfirmationIdentificationFormFields.Drfou]: isEmptyInn(
            dataProfile.confirmedProfile.DRFOU,
          )
            ? dataProfile.confirmedProfile.PASSPORT
            : dataProfile.confirmedProfile.DRFOU,
        });

      const requestPayload: TokenKepCertificateRequest = {
        smsCode,
        requestDataSignature: signatureKeyPkcs10Request,
        requestDataAgreement: agreementKeyPkcs10Request,
        userKeystoreType: UserKeystoreType.HardWired,
        userKeystoreName: tokenOption.content.serial,
      };

      const certificate = await confirmModal<ImportCertificateData, AwaitCertificateFormProps>(
        AwaitCertificateModal,
        {
          requestPayload,
          customerId,
          dataHash: dataProfile.dataHash,
          certificateType: isGenerateUEP && CertificateType.Uep,
        },
        640,
        undefined,
        true,
      );

      if (!Object.keys(certificate).length) return gotoCertificatesPage();

      try {
        await CipherService.createSessionContainer({
          ticketUuid,
          keyStorePath: tokenOption.content.activeModePath,
        });

        await importCertificates(ticketUuid, password, certificate);
      } catch (e) {
        await confirmationModal(
          translate('form.certificates.certificates-error.label'),
          false,
          false,
        );
      } finally {
        ticketUuid && (await CipherService.closeSession(ticketUuid));
      }

      return gotoCertificatesPage();
    }

    const cloudRequestPayload: CloudKepCertificateRequest = {
      smsCode,
      customerId,
      dataHash: dataProfile.dataHash,
      pin: password,
      puk,
      keystoreType: isTokenMode ? UserKeystoreType.HardWired : UserKeystoreType.CloudStorage,
      certificateType: isGenerateUEP ? CertificateType.Uep : null,
    };

    try {
      await api.certificate.createCloudCertificate(cloudRequestPayload);
    } catch (e: any) {
      await confirmationModal(parseErrorMessage(e), false, false);
      return;
    }

    gotoCertificatesPage();
  };

export const CreateKepCertificatePage = withKepCertificate({
  fetchOnMount,
  createOnSave,
  createFetchCerts,
  isCreate: true,
  title: 'front.cert-page-new.title.label',
  labelButtonOnSave: 'front.cert-page-new.create-key-button.label',
  customerPrivilege: Privileges.keysAddNew,
})(CreateCert);
