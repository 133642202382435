import React from 'react';

import { api } from 'api';
import { Button } from 'components/buttons/Button';
import { DefaultForm } from 'components/forms/formParts';
import { TextAreaField } from 'components/forms/inputs/TextAreaField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { WarningIcon } from 'components/icons';
import { PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';

import './RejectDocumentModal.scss';

interface RejectModalProps {
  orderId: number;
}

interface RejectModalState {
  comment: string;
}

const RejectDocumentModal = ({ orderId, resolve }: PropsWithConfirmModal<RejectModalProps>) => {
  const { progress, handleSubmit } = useForm<RejectModalState>();

  const handleRejectDocument = async (formData: RejectModalState) => {
    await api.documentManagement.toRejectDocument(orderId, formData.comment);
    await resolve(true);
  };

  return (
    <div className="reject-modal">
      <div className="reject-modal__error">
        <WarningIcon />
        <h1>{translate('front.document-management.warning.label')}</h1>
        {translate('front.document-management.reject-message.label')}
      </div>
      <DefaultForm disabled={progress}>
        <TextAreaField
          name="comment"
          label="front.document-management.comment.label"
          minLength={5}
          maxLength={420}
          rows={1}
          hasAutoGrowing={true}
          required
        />

        <div className="reject-modal__buttons">
          <Button
            color="primary"
            size="lg"
            label="front.confirm-modal.yes.button.label"
            className="reject-modal__button"
            type="submit"
            onClick={e => handleSubmit(handleRejectDocument, e)}
          />

          <Button
            color="secondary"
            size="lg"
            label="front.confirm-modal.no.button.label"
            className="reject-modal__button"
            onClick={async () => await resolve(false)}
          />
        </div>
      </DefaultForm>
    </div>
  );
};

export default withForm(RejectDocumentModal);
