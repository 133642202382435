import * as React from 'react';
import { Menu, TableColumnProps } from 'react-data-table';

import classnames from 'classnames';
import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DN } from 'api/AuthService';
import { getStatusColor } from 'api/CertificateService/CertificateService';
import { CertificateStatus, CertificateType, UserKeystoreType } from 'api/CertificateService/enums';
import { Certificate } from 'api/CertificateService/interfaces';
import { Keys } from 'components/icons';
import { Status } from 'components/layout/Status';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { CertificateDetail } from 'pages/Profile/Certificates/CertificateDetails';
import { CertificatesFilter } from 'pages/Profile/Certificates/CertificatesFilter';
import { ChangePasswordKepModal } from 'pages/Profile/Certificates/Modals/ChangePasswordKepModal';
import { ChangePasswordModal } from 'pages/Profile/Certificates/Modals/ChangePasswordModal';
import { DeleteModal } from 'pages/Profile/Certificates/Modals/DeleteModal';
import { ReissueAuthorModal } from 'pages/Profile/Certificates/Modals/ReissueAuthorModal';
import {
  allowCertificate,
  allowReissue,
  allowRevokeCertificate,
  isAvtorCertificate,
  isAvtorCipherCskCertificate,
  isESeal,
  parseStatusToLocalization,
} from 'pages/Profile/Certificates/utils';
import { TestSelectorsEnum } from 'test/TestSelectors';
import { isString } from 'utils/isData';

import { ActivateModal } from './Modals/ActivateModal';
import { NoConnectionModal } from './Modals/NoConnectionModal';
import { ReissueModal } from './Modals/ReissueModal/ReissueModal';
import { RevokeModal } from './Modals/RevokeModal';
import { isCertificateNotExpired } from './utils';

const userKeystoreTypeTranslation = {
  [UserKeystoreType.FileStorage]: translate('front.cert-page.col-key-type-file.label'),
  [UserKeystoreType.HardWired]: translate('front.cert-page.col-key-type-token.label'),
  [UserKeystoreType.CloudStorage]: translate('front.cert-page.col-key-type-cloud.label'),
};

const certificateTypeTranslation: Obj<string> = {
  [CertificateType.Seal]: translate('front.certificate-confirmation.e-seal.label'),
  [CertificateType.Kep]: translate('front.certificate-confirmation.kep.label'),
  [CertificateType.Uep]: translate('front.certificate-confirmation.uep.label'),
};

const getCertificateTypeTranslation = (
  keystore: UserKeystoreType,
  certificateType: CertificateType,
) => {
  if (certificateType === CertificateType.Seal) {
    return `${certificateTypeTranslation[CertificateType.Seal]}(${
      certificateTypeTranslation[CertificateType.Kep]
    })`;
  }

  return `${userKeystoreTypeTranslation[keystore]} (${certificateTypeTranslation[certificateType]})`;
};

const columns: TableColumnProps<Certificate & { user: DN }>[] = [
  {
    label: 'front.cert-page.col-the-owner-(dn).label',
    render: row => <DataColumn title={row.user?.CN} />,
    sort: 'customer_user.name',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.cert-page.col-organization.label',
    render: row => <DataColumn title={row.user?.O} />,
    sort: 'customer.name',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.cert-page.col-status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.status)}>{parseStatusToLocalization(row.status)}</Status>
      </DataColumn>
    ),
    sort: 'status',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.cert-page.col-multikey.label',
    render: row =>
      row.isUserCert && (
        <DataColumn>
          <Keys dataTestId={TestSelectorsEnum.CertificatesMultiKeyIcon} />
        </DataColumn>
      ),
    sort: 'customerUserId',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.cert-page.col-the-serial-number.label',
    render: row => <DataColumn title={row.certificateSN} />,
    sort: 'certificateSn',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.cert-page.col-release-date.label',
    render: row => <DataColumn title={format.dateTime(row.validFrom)} />,
    sort: 'validFrom',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.cert-page.col-expiration-date.label',
    render: row => {
      const isNotValid =
        allowReissue(format.diffWithCurrentDate(row.validTo)) &&
        row.status === CertificateStatus.Active;

      const cx = classnames({
        'expired-date': isNotValid,
      });

      return <DataColumn title={format.dateTime(row.validTo)} titleClassName={cx} />;
    },
    sort: 'validTo',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.cert-page.col-key-type.label',
    render: row => (
      <DataColumn
        title={getCertificateTypeTranslation(row.userKeystoreType, row.certificateType)}
      />
    ),
    sort: 'userKeystoreType',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: (row: Certificate): Menu<Certificate>[] => [
      {
        label: 'front.cert-page.menu-reissue.label',
        onClick: async () => {
          if (isAvtorCertificate(row)) {
            if (!(await api.certificate.isUmcaAvailable())) {
              await confirmModal(
                NoConnectionModal,
                {
                  description: 'front.certificates.error-modal-umca-text.label',
                  useCipherApp: false,
                },
                426,
              );

              return;
            }

            if (
              await confirmationModal(
                'front.cert-page.reissue-modal.valid-key-will-be -deleted.label',
              )
            ) {
              if (await confirmModal(ReissueAuthorModal, { certificate: row }, 426)) {
                return goto(pages.profile.createCertificate);
              }

              return;
            }

            return;
          }

          if (await confirmModal(ReissueModal, { certificate: row }, 426)) {
            await confirmationModal(
              translate('front.operation-executed-successfully.label'),
              true,
              false,
            );

            return;
          }
        },
        isVisible:
          allowCertificate(row) &&
          [CertificateStatus.Active].includes(row.status) &&
          isActionAllowed(
            [isESeal(row.certificateType) ? Privileges.sealReissue : Privileges.keysReissue],
            row.customerId,
          ) &&
          allowReissue(format.diffWithCurrentDate(row.validTo)) &&
          isCertificateNotExpired(row.validTo),
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-continue.label',
        onClick: async () =>
          await api.certificate.reissueCertificateApi(row.id, row.certificate, false),
        isVisible:
          isActionAllowed([Privileges.keysReissue], row.customerId) &&
          [CertificateStatus.New].includes(row.status) &&
          [CertificateStatus.New, CertificateStatus.Processing].includes(row.requestStatus) &&
          isString(row.certificateSN),
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-activate.label',
        onClick: async () => {
          if (!(await api.certificate.isUmcaAvailable())) {
            await confirmModal(
              NoConnectionModal,
              {
                description: 'front.certificates.error-modal-umca-text.label',
                useCipherApp: false,
              },
              426,
            );
            return;
          }
          if (await confirmModal(ActivateModal, { certificate: row }, 426)) {
            await confirmationModal(
              translate('front.operation-executed-successfully.label'),
              true,
              false,
            );
          }
        },
        isVisible:
          allowCertificate(row) &&
          [CertificateStatus.Certified].includes(row.status) &&
          isActionAllowed([Privileges.keysActivate], row.customerId),
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-activate-multikey.label',
        onClick: async () => {
          const isActivate = await confirmationModal(
            `${translate('front.cert-page.menu-activate-multikey.label')}?`,
          );

          if (isActivate) {
            await api.certificate.activateMultiCertificate(row.id, true);
          }
        },
        isVisible:
          !isESeal(row.certificateType) &&
          [CertificateStatus.Active].includes(row.status) &&
          row.allowUserCert,
        isLoading: true,
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-deactivate-multikey.label',
        onClick: async () => {
          const isDeactivate = await confirmationModal(
            `${translate('front.cert-page.menu-deactivate-multikey.label')}?`,
          );

          if (isDeactivate) {
            await api.certificate.activateMultiCertificate(row.id, false);
          }
        },
        isVisible: [CertificateStatus.Active].includes(row.status) && row.isUserCert,
        isLoading: true,
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-revoke.label',
        onClick: async () =>
          await confirmModal(
            RevokeModal,
            {
              certificateId: row.id,
              userKeystoreType: row.userKeystoreType,
            },
            430,
          ),
        isVisible:
          allowRevokeCertificate(row) &&
          [CertificateStatus.Active, CertificateStatus.Blocked].includes(row.status) &&
          isActionAllowed(
            [isESeal(row.certificateType) ? Privileges.sealDelete : Privileges.keysDelete],
            row.customerId,
          ),
        isLoading: true,
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-change-password.label',
        onClick: async () => {
          const isAvtorKey = isAvtorCertificate(row);

          if (isAvtorKey && !(await api.certificate.isUmcaAvailable())) {
            await confirmModal(
              NoConnectionModal,
              {
                description: 'front.certificates.error-modal-umca-text.label',
                useCipherApp: false,
              },
              426,
            );

            return;
          }

          if (
            await confirmModal(
              isAvtorKey ? ChangePasswordModal : ChangePasswordKepModal,
              { certificate: isAvtorKey ? row.certificate : row },
              426,
            )
          ) {
            await confirmationModal(
              translate('front.operation-executed-successfully.label'),
              true,
              false,
            );
          }
        },
        isVisible:
          allowCertificate(row) &&
          [CertificateStatus.Active].includes(row.status) &&
          isActionAllowed(
            [isESeal(row.certificateType) ? Privileges.sealChangePin : Privileges.keysChangePin],
            row.customerId,
          ),
      },
      {
        label: isAvtorCipherCskCertificate(row)
          ? 'front.cert-page.menu-delete.label'
          : 'front.cert-page.menu-disconnect.label',
        onClick: async () => {
          const isAvtorKey = isAvtorCertificate(row);

          if (isAvtorKey && !(await api.certificate.isUmcaAvailable())) {
            await confirmModal(
              NoConnectionModal,
              {
                description: 'front.certificates.error-modal-umca-text.label',
                useCipherApp: false,
              },
              426,
            );

            return;
          }

          if (await confirmModal(DeleteModal, { certificate: row }, 428)) {
            await confirmationModal(
              translate('front.operation-executed-successfully.label'),
              true,
              false,
            );
          }
        },
        isVisible:
          ([CertificateStatus.NotValid, CertificateStatus.New, CertificateStatus.Rejected].includes(
            row.status,
          ) ||
            !isAvtorCipherCskCertificate(row)) &&
          isActionAllowed(
            [isESeal(row.certificateType) ? Privileges.sealDelete : Privileges.keysDelete],
            row.customerId,
          ),
        isReloadable: true,
      },
      {
        label: 'front.cert-page.menu-print.label',
        onClick: async () => await api.certificate.getCertificatePdf(row.id),
        isVisible: [
          CertificateStatus.Active,
          CertificateStatus.Certified,
          CertificateStatus.NotValid,
          CertificateStatus.New,
          CertificateStatus.Rejected,
          CertificateStatus.Blocked,
        ].includes(row.status),
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

export const CertificatesPage = () => (
  <DataTable
    name={TableNamesEnum.CertificatesTable}
    columns={columns}
    fetchRows={api.certificate.getUserCertificates}
    filterComponent={CertificatesFilter}
    expandRowComponent={CertificateDetail}
    isExpandedRowComponentDisabled={row => !row.isUserCert}
    hidePagination
  />
);
