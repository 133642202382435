import * as React from 'react';
import { Col } from 'react-grid';

import { api } from 'api';
import { SignOrder } from 'api/interfaces';
import { SmsField } from 'components/forms/SmsField';
import { useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { AgreementFormResult } from 'pages/utils/components/AgreementFormResult/AgreementFormResult';
import { SmsModalCounter } from 'pages/utils/components/SmsModalCounter/SmsModalCounter';
import { WrapperAgreePaymentInfoForm } from 'pages/utils/components/WrapperAgreePaymentInfoForm/WrapperAgreePaymentInfoForm';
import { SmsModalFormFieldsEnum } from 'pages/utils/enums';
import {
  SmsModalFormPayloadInterface,
  SmsModalFormProps,
  SmsModalFormStateInterface,
} from 'pages/utils/interfaces';
import { isExist } from 'utils/isData';

const SignSmsModalForm = ({ ids, ordersCount, resolve }: SmsModalFormProps) => {
  const sendSms = async () => {
    const response = await api.sms.ordersSignSmsRequest({ orderIdList: ids });

    setPayload({
      smsLength: response.smsLength,
      tableRows: response.params,
    });
  };

  const { progress, error, handleSubmit, setPayload, payload, initializing } = useForm<
    SmsModalFormStateInterface,
    SmsModalFormPayloadInterface
  >(sendSms);

  const signOrders = async (code: string) => {
    const signData: SignOrder[] = ids.map(id => ({
      signFactor: 'SMS',
      certificateSn: null,
      certificateCaSn: null,
      signatures: { [id]: code },
    }));
    const { data: signResult } = await api.payments.signOrder(signData);
    const hasError = isExist(signResult.find(item => item.hasError));

    if (ordersCount > 1 || hasError) {
      const errors = signResult.filter(item => item.hasError);
      const successCount = signResult.length - errors.length;

      await confirmModal<string>(AgreementFormResult, { errors, successCount, ordersCount }, 650);
    }
  };

  const onSign = async (data: SmsModalFormStateInterface) => {
    await signOrders(data.code);
    await resolve(true);
  };

  const { smsLength, tableRows } = payload;

  return (
    <WrapperAgreePaymentInfoForm
      title="front.sms-modal.sms-sign-password.label"
      description="front.sms-modal.sms-sent-to-phone.label"
      confirmButtonLabel="front.sms-modal.sms-sign-button.label"
      resolve={resolve}
      tableRows={tableRows}
      error={error}
      handleSubmit={e => handleSubmit(onSign, e)}
      progress={progress}
    >
      <Col>
        <SmsField
          label="front.login-form.sms.label"
          name={SmsModalFormFieldsEnum.Code}
          length={smsLength}
          autoFocus
        />
      </Col>
      <SmsModalCounter initializing={initializing} sendSms={sendSms} payload={payload} />
    </WrapperAgreePaymentInfoForm>
  );
};

export const SignSmsModal = withForm(SignSmsModalForm);
