import React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType, OrderState, PaymentTypes } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { OrderRequest } from 'api/interfaces';
import { getTotal, SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { ActionComponentType } from 'components/react-data-table/types';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import { TotalCurrency } from 'components/TotalCurrency/TotalCurrency';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import {
  electronicSignAction,
  getTranslateAction,
  signActions,
  smsSignAction,
} from 'pages/Payments/general';
import { DEFAULT_PAYMENT_TYPES } from 'pages/Payments/InternalPayment/utils';
import {
  addEscrouTypeToOrderType,
  isAllowedActionSign,
  isVisibleAction,
} from 'pages/Payments/utils';
import { multiplyPinTokenConfirmationPayment } from 'pages/utils/ConfirmationPayments/PinTokenConfirmationPaymentsModal/general';
import {
  multiplySmsConfirmationPayments,
  smsConfirmationPayments,
} from 'pages/utils/ConfirmationPayments/SmsConfirmationPaymentsModal/general';

import { InternalPaymentFilter } from './InternalPaymentFilter';

interface FilterRequest extends Omit<OrderRequest, 'orderStates'> {
  orderStates: string;
}

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.working-documents-table.number.label',
    render: row => (
      <DataColumn title={row?.base?.orderNumber} subTitle={format.date(row?.base?.orderDate)} />
    ),
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.sender.label',
    render: row => (
      <DataColumn
        title={row?.financial?.payerName}
        subTitle={row?.financial?.payerIban || row?.financial?.payerAccount}
      />
    ),
    sort: 'payerName',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.receiver.label',
    render: row => (
      <DataColumn
        title={row?.financial?.receiverName}
        subTitle={row?.financial?.receiverIban || row?.financial?.receiverAccount}
      />
    ),
    sort: 'receiverName',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.working-documents-table.purpose.label',
    render: row => <DataColumn title={row?.financial?.purpose} />,
    sort: 'purpose',
    hint: row => row?.financial?.purpose,
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row?.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.working-documents-table.amount.label',
    render: row => (
      <DataColumn
        title={format.getAmount(row?.financial?.amount)}
        subTitle={row?.financial?.isoCode}
      />
    ),
    sort: 'amount',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    renderMenu: order => [
      {
        label: getTranslateAction(OrderActionType.REJECT),
        onClick: async () => await api.payments.executeAction(order.id, OrderActionType.REJECT),
        isReloadable: true,
        isVisible: isVisibleAction(order, OrderActionType.REJECT),
      },
      {
        label: getTranslateAction(OrderActionType.REVOKE),
        onClick: async () => {
          await api.payments.withdrawOrders([order.id]);
          await confirmationModal(
            translate('front.working-documents-table.actions-revoke-sent.label'),
            true,
            false,
          );
        },
        isVisible: isVisibleAction(order, OrderActionType.REVOKE),
        confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.payments.deleteOrder(order.id),
        isVisible: isVisibleAction(order, OrderActionType.DELETE),
        confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => signActions(order),
        isVisible: isAllowedActionSign(order, OrderActionType.SIGN),
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.VIEW),
        onClick: () => goto(pages.internalPayments.tabs.details(order.id.toString())),
        isVisible: isVisibleAction(order, OrderActionType.VIEW),
      },
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () => goto(pages.internalPayments.tabs.details(order.id.toString())),
        isVisible: isVisibleAction(order, OrderActionType.EDIT),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () =>
          goto(
            pages.internalPayments.tabs.details('new', {
              copyFrom: order.id.toString(),
            }),
          ),
        isVisible: isVisibleAction(order, OrderActionType.COPY),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: () => api.payments.getOrderPdf(order.id),
        isVisible: isVisibleAction(order, OrderActionType.PRINT),
      },
      {
        label: 'front.working-documents-table.actions-schedule.label',
        onClick: () =>
          goto(
            `${pages.regularPayment.tabs.schedule(String(order.id), 'new')}?type=${
              order.base.orderType
            }`,
          ),
        isVisible: isVisibleAction(order, OrderActionType.MAKE_REGULAR),
      },
      {
        label: getTranslateAction(OrderActionType.CONFIRM_AF_OTP),
        onClick: async () => await smsConfirmationPayments([order.id]),
        isVisible: isVisibleAction(order, OrderActionType.CONFIRM_AF_OTP),
        isReloadable: true,
      },
      {
        label: getTranslateAction(OrderActionType.CONFIRM_AF_PIN),
        onClick: async () => await multiplyPinTokenConfirmationPayment([order]),
        isVisible: isVisibleAction(order, OrderActionType.CONFIRM_AF_PIN),
        isReloadable: true,
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action[] = [
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async data => electronicSignAction(data),
    isReloadable: true,
    isVisible: selected =>
      selected.some(
        item =>
          item.base.orderState !== OrderState.NeedSms &&
          isAllowedActionSign(item, OrderActionType.SIGN),
      ),
  },

  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async data => smsSignAction(data),
    isReloadable: true,
    color: 'secondary',
    isVisible: selected => selected.some(item => isAllowedActionSign(item, OrderActionType.SIGN)),
  },
  {
    label: getTranslateAction(OrderActionType.CONFIRM_AF_PIN),
    action: async data => multiplyPinTokenConfirmationPayment(data),
    isReloadable: true,
    color: 'secondary',
    isVisible: selected =>
      selected.some(item => isVisibleAction(item, OrderActionType.CONFIRM_AF_PIN)),
  },
  {
    label: getTranslateAction(OrderActionType.CONFIRM_AF_OTP),
    action: async data => await multiplySmsConfirmationPayments(data),
    isReloadable: true,
    color: 'secondary',
    isVisible: selected =>
      selected.some(item => isVisibleAction(item, OrderActionType.CONFIRM_AF_OTP)),
  },
  {
    label: 'front.working-documents-table.actions-revoke.label',
    action: async data => {
      const orders = data.filter(item => isVisibleAction(item, OrderActionType.REVOKE));
      if (!orders.length) {
        return;
      }
      await api.payments.withdrawOrders(orders.map(item => item.id));
      await confirmationModal(
        translate('front.working-documents-table.actions-revoke-sent.label'),
        true,
        false,
      );
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.label',
    isVisible: selected => selected.some(item => isVisibleAction(item, OrderActionType.REVOKE)),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async data => {
      const orders = data.filter(item => isVisibleAction(item, OrderActionType.DELETE));
      if (!orders.length) {
        return;
      }
      for (let i = 0; i < orders.length; i += 1) {
        await api.payments.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.working-documents-table.actions-delete-confirmation.label',
    isVisible: selected => selected.some(item => isVisibleAction(item, OrderActionType.DELETE)),
    isReloadable: true,
  },
  {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async selected =>
      await confirmModal(
        PrintingDocuments,
        {
          data: selected.filter(item => isVisibleAction(item, OrderActionType.PRINT)),
          objectCode: ExportType.DOMESTIC_PAYMENT_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected => selected.some(item => isVisibleAction(item, OrderActionType.PRINT)),
    isReloadable: false,
  },
];

const fetchDocs = async (request: FilterRequest) => {
  const isRejectedStatus = request.orderStates === OrderState.Rejected;

  const types = request.types
    ? (addEscrouTypeToOrderType(request.types) as PaymentTypes[])
    : DEFAULT_PAYMENT_TYPES;

  const orderStates = api.payments.getPaymentStatusesByKey(request.orderStates || null);

  const { rows, total } = await api.payments.getWorkingDocs({
    ...request,
    types,
    orderStates: isRejectedStatus ? [...orderStates, OrderState.Revoked] : orderStates,
    withCurrencyTotals: true,
  });

  return {
    rows,
    total: getTotal(total),
  };
};

const getCurrencyAmount = (row: DemandResponse): [string, number] => [
  row.financial.isoCode,
  row.financial.amount,
];

const ActionsComponent: ActionComponentType<DemandResponse> = ({
  total,
  selectedItems,
  children,
}) => (
  <TotalCurrency<DemandResponse>
    total={total}
    selectedItems={selectedItems}
    getCurrencyAmount={getCurrencyAmount}
  >
    {children}
  </TotalCurrency>
);

export const InternalPaymentsPage: React.FC = () => (
  <Page title={translate('front.working-documents-table.label')}>
    <DataTable
      name={TableNamesEnum.WorkingDocs}
      columns={columns}
      actions={actions}
      fetchRows={fetchDocs}
      defaultSort="created"
      defaultOrder={SortOrder.Desc}
      filterComponent={InternalPaymentFilter}
      actionsComponent={ActionsComponent}
      onRowClick={order => goto(pages.internalPayments.tabs.details(order.id))}
      shouldUseStorageSize
    />
  </Page>
);
