import React from 'react';
import { Col } from 'react-grid';
import { useSelector } from 'react-redux';

import { ConstantEnum } from 'api/enums';
import { useCountdown } from 'hooks/useCountDown';
import { translate } from 'i18n/translator';
import { SmsModalFormPayloadInterface } from 'pages/utils/interfaces';
import { selectConstants } from 'store/selectors';

import './SmsModalCounter.scss';

interface SmsModalCounterProps {
  initializing: boolean;
  payload: SmsModalFormPayloadInterface;
  sendSms: () => void;
}

export const SmsModalCounter = React.memo(
  ({ initializing, sendSms, payload }: SmsModalCounterProps) => {
    const smsValidPeriod = +useSelector(selectConstants())[ConstantEnum.SmsValidPeriod];

    const { time, start: startResendCounter, started } = useCountdown({ seconds: smsValidPeriod });

    React.useEffect(() => startResendCounter(), []);

    React.useEffect(() => {
      if (!initializing && smsValidPeriod) {
        startResendCounter();
      }
    }, [initializing, payload]);

    return (
      <>
        <Col>
          <div className="expired-sms">
            {!initializing && !started ? (
              <span className="expired-sms__expired">
                {translate('front.sms-modal.session-expired.label')}
              </span>
            ) : (
              !initializing &&
              started && (
                <>
                  <span className="expired-sms__title">
                    {translate('front.sms-modal.password-is-valid.label')}
                  </span>
                  <span className="expired-sms__timer">{time}</span>
                </>
              )
            )}
          </div>
        </Col>
        <Col>
          <div className="resend-sms" onClick={!started ? sendSms : null}>
            <span className="resend-sms__title">
              {translate('front.sms-modal.sms-resend-button.label')}
            </span>
            {!initializing && started ? (
              <span className="resend-sms__timer">
                {translate('front.cert-page.sms-modal.sms-resend-timer.label')}
                {` ${time}`}
              </span>
            ) : null}
          </div>
        </Col>
      </>
    );
  },
);
