import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';

import { api } from 'api';
import { KeyType } from 'api/CertificateService/enums';
import { ActionEnum } from 'api/enums';
import { Option } from 'api/Service';
import { Effects, useForm } from 'components/forms/ValidatingForm/useForm';
import { withForm } from 'components/forms/withForm';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { CustomerSelectModal } from 'pages/Login/CustomerSelectModal/CustomerSelectModal';
import {
  CustomerSelectModalProps,
  CustomerSelectMode,
} from 'pages/Login/CustomerSelectModal/types';
import { GetButtonsComponent } from 'pages/Login/Steps/SignatureLogin/GetButtonsComponent';
import { SignatureComponent } from 'pages/Login/Steps/SignatureLogin/SignatureComponent';
import { useLoginContext } from 'pages/Login/useLoginContext';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { isAvtorCertificate } from 'pages/Profile/Certificates/utils';
import { UserActions } from 'store/actions/user';
import { UserState } from 'store/reducers/user';
import { selectUser } from 'store/selectors';

import './SignatureLogin.scss';

interface Fields {
  password: string;
  token: string;
}

interface Payload {
  tokenOptions: Option[];
}

const UmkaSignatureLogin = () => {
  const [isSearchTokens, setIsSearchTokens] = React.useState(false);

  const dispatch = useDispatch();

  const dispatchUser = (user: UserState) => dispatch(UserActions.setUser(user));

  const signingString = useSelector(createSelector(selectUser(), user => user.value));
  const { goToFirstStep, setCurrentFactor, certificates } = useLoginContext();

  const fetchToken = async ({ setPayload, setFields }: Partial<Effects<Fields, Payload>>) => {
    const tokenOptions = await api.certificate.getTokens(KeyType.TOKEN);

    const token = tokenOptions[0]?.value;
    setFields({ token });
    setPayload({ tokenOptions });
  };

  const { progress, error, payload, setPayload, updateData, handleSubmit } = useForm<
    Fields,
    Payload
  >(async ({ setPayload, setFields }) => {
    await fetchToken({ setPayload, setFields });
  });

  const onSearchToken = async () => {
    setIsSearchTokens(true);
    await fetchToken({ setPayload, setFields: updateData });
    setIsSearchTokens(false);
  };

  const onSave = async (formData: Fields) => {
    const { token: tokenSerial, password } = formData;
    validatePasswordWithException(password, '', 'front.form.pin-code.error');

    const token = payload.tokenOptions.find(item => item.value === tokenSerial).content;
    await api.certificate.changePin(password, password, token, ActionEnum.LoginWithAuthor);

    const tokenCertificates = await api.certificate.getCertifcatesUmka({
      Hw: true,
      ReadCertificates: true,
      EnfoldFileKeyStores: false,
      Serial: token.Serial,
    });

    const certificatesSN = tokenCertificates?.map(cert => cert.certificateSerialNumber);

    const filteredCertificates = certificates.filter(
      certificate =>
        isAvtorCertificate(certificate) && certificatesSN.includes(certificate.certificateSN),
    );

    if (filteredCertificates.length === 0) {
      return await confirmModal(NoConnectionModal, {
        renderButtons,
        description: 'front.login-form.certificate.token-no-valid-keys.label',
      });
    }

    let signedString = '';

    for (const cert of filteredCertificates) {
      if (!signedString) {
        signedString = await api.certificate.sign(
          signingString,
          cert.certificate,
          password,
          'cms-attached',
        );
      } else {
        signedString = await api.certificate.sign(
          signingString,
          cert.certificate,
          password,
          null,
          signedString,
        );
      }
    }

    await api.auth.signatureLogin({ sign: signedString });

    const user = await confirmModal<UserState, CustomerSelectModalProps>(
      CustomerSelectModal,
      { mode: CustomerSelectMode.auth },
      undefined,
      {},
      true,
    );

    dispatchUser({ ...user });
  };

  const renderButtons = GetButtonsComponent({
    goToFirstStep,
    setCurrentFactor,
    fetchToken,
    setPayload,
    updateData,
  });

  return (
    <SignatureComponent
      progress={progress}
      error={error}
      payload={payload}
      onSearchToken={onSearchToken}
      isSearchTokens={isSearchTokens}
      goToFirstStep={goToFirstStep}
      handleSubmit={e => handleSubmit(onSave, e)}
    />
  );
};

export default withForm(UmkaSignatureLogin);
