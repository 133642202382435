import * as React from 'react';
import { Col, Container, Row } from 'react-grid';

import { api } from 'api';
import { UserKeystoreType } from 'api/CertificateService/enums';
import { CheckSmsActionEnum } from 'api/enums';
import { Button } from 'components/buttons/Button';
import { ConfirmationSmsModal } from 'components/modals/ConfirmationSmsModal';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { translate } from 'i18n/translator';
import { allowedRevokeCertificateTypes, parseErrorMessage } from 'pages/Profile/Certificates/utils';

interface Props {
  certificateId: number;
  customerId: number;
  userKeystoreType: UserKeystoreType;
}

export const RevokeModal: React.FC<PropsWithConfirmModal<Props>> = ({
  certificateId,
  userKeystoreType,
  resolve,
}) => {
  const onRevoke = async () => {
    let smsCode: string;

    if (allowedRevokeCertificateTypes.includes(userKeystoreType)) {
      smsCode = await confirmModal<string>(ConfirmationSmsModal, {
        action: CheckSmsActionEnum.ADD_CERTIFICATE,
      });
      if (!smsCode) return resolve(true);
    }

    try {
      await api.certificate.revokeCertificate(certificateId, smsCode);
    } catch (e: any) {
      await confirmationModal(parseErrorMessage(e), false, false);
    }

    resolve(true);
  };

  const onClose = () => resolve(false);

  return (
    <div>
      <h3>{translate('front.cert-page.revoke-modal.title.label')}</h3>
      <Container>
        <Row>
          <Col sm={6}>
            <Button onClick={onRevoke} size="md" type="submit" block>
              {translate('front.cert-page.revoke-modal.revoke-button.label')}
            </Button>
          </Col>
          <Col sm={6}>
            <Button onClick={onClose} color="secondary" size="md" block>
              {translate('front.cert-page.revoke-modal.cancel-button.label')}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
