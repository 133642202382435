import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { isVisibleAction } from 'pages/Payments/utils';
import { checkHasConfirmationFactor } from 'pages/utils/utils';

import { SmsConfirmationPaymentsModal } from './SmsConfirmationPaymentsModal';

export const smsConfirmationPayments = async (
  ids: number[],
  ordersCount?: number,
): Promise<void> => {
  const hasAccessToConfirm = await checkHasConfirmationFactor(ids);

  if (!hasAccessToConfirm) {
    return;
  }

  return await confirmModal<void>(
    SmsConfirmationPaymentsModal,
    {
      ids,
      ordersCount,
    },
    426,
  );
};

export const multiplySmsConfirmationPayments = async (data: DemandResponse[]) => {
  const filteredOrders = data.filter(order =>
    isVisibleAction(order, OrderActionType.CONFIRM_AF_OTP),
  );

  if (data.length) {
    return smsConfirmationPayments(
      filteredOrders.map(item => item.base.id),
      data.length,
    );
  }

  return smsConfirmationPayments(filteredOrders.map(item => item.base.id));
};
