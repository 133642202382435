import React from 'react';
import { Col } from 'react-grid';

import { Base64 } from 'js-base64';

import { api } from 'api';
import { KeyType } from 'api/CertificateService/enums';
import { ParsedCertificate } from 'api/CertificateService/UmcaModule/interfaces';
import { isUmcaVersionAvailable } from 'api/CertificateService/UmcaModule/UmcaService';
import { SignOrder } from 'api/interfaces';
import { SelectField } from 'components/forms/inputs/SelectField';
import { confirmModal, PropsWithConfirmModal } from 'components/modals/globalModal/GlobalModal';
import { validateMessages } from 'components/validateMessages';
import { translate } from 'i18n/translator';
import { ExpiredError } from 'pages/Profile/Certificates/CertificateEngine/ExpiredError';
import { SearchTokenButton } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenButton';
import { SearchTokenLabel } from 'pages/Profile/Certificates/CertificateEngine/SearchTokenLabel';
import {
  CreateFilteringCustomers,
  CreateOnSave,
  FetchOnMount,
} from 'pages/Profile/Certificates/CertificateEngine/types';
import { useCertificateDetails } from 'pages/Profile/Certificates/CertificateEngine/useCertificateDetails';
import { useSearchToken } from 'pages/Profile/Certificates/CertificateEngine/useSearchToken';
import { withCertificate } from 'pages/Profile/Certificates/CertificateEngine/withCertificate';
import { CertificateFieldNames } from 'pages/Profile/Certificates/KepCertificate/enums';
import { AgreementFormResult } from 'pages/utils/components/AgreementFormResult/AgreementFormResult';
import { signOrdersInChunks } from 'pages/utils/SignOrders/CertificateSign/utils';
import { PropsSign } from 'pages/utils/SignOrders/signOrders';
import { toUniqueArray } from 'pages/utils/utils';
import { isExist } from 'utils/isData';
import { safeResolvedPromises } from 'utils/safeResolvedPromises';

type Props = PropsWithConfirmModal<PropsSign>;

interface SignData {
  cms: string;
  signatureString: string;
}

const EXISTING_IN_SYSTEM = true;

const AddCertToken: React.FC = () => {
  const { form, mode, customer } = useCertificateDetails();
  const { payload } = form;
  const { onSearchToken, isSearchTokens } = useSearchToken();

  const onChangeStore = async (token: string, option: { Serial: string }) => {
    const { Serial } = option;
    const edsKeyOptions = await api.certificate.getCertificatesOptions({
      mode,
      customerIds: [customer.id],
      fetchFilter: { Serial },
      existingInSystem: EXISTING_IN_SYSTEM,
    });
    const edsKey = edsKeyOptions[0]?.value;

    form.updateData({ edsKey });
    form.setPayload({ edsKeyOptions });
  };

  const isDisabledKey = payload?.storeOptions?.length === 0;

  return (
    <>
      <Col sm={10}>
        <SelectField
          label="front.cert-page-add.input-token.select-umca-token.label"
          onSelectOption={onChangeStore}
          name={CertificateFieldNames.Store}
          options={payload.storeOptions}
          isSearchable={false}
          required
        />
      </Col>
      <SearchTokenButton onSearchToken={onSearchToken} />
      <SearchTokenLabel values={payload.storeOptions} isSearchTokens={isSearchTokens} />
      <ExpiredError />
      <Col sm={12}>
        <SelectField
          label="front.cert-page-add.input-token.select-key-umca-token.label"
          name={CertificateFieldNames.EdsKey}
          options={payload.edsKeyOptions}
          isSearchable={false}
          disabled={isDisabledKey}
          required
        />
      </Col>
    </>
  );
};

const AddCertFile: React.FC = () => {
  const { form } = useCertificateDetails();
  const {
    payload: { edsKeyOptions },
  } = form;
  const { onSearchToken, isSearchTokens } = useSearchToken();

  return (
    <>
      <Col sm={10}>
        <SelectField
          label={translate('front.cert-page-add.input-token.select-keystore.label')}
          name={CertificateFieldNames.EdsKey}
          options={edsKeyOptions}
          isSearchable={false}
          required
        />
      </Col>
      <SearchTokenButton onSearchToken={onSearchToken} />
      <SearchTokenLabel values={edsKeyOptions} isSearchTokens={isSearchTokens} />
      <ExpiredError />
    </>
  );
};

const signOrder = async (
  signData: SignData,
  orderId: number,
  certificateBase64: string,
  password: string,
): Promise<string> => {
  if (signData.cms) {
    return await api.certificate.sign(
      Base64.decode(signData.signatureString),
      certificateBase64,
      password,
      null,
      signData.cms,
    );
  }

  return await api.certificate.sign(
    Base64.decode(signData.signatureString),
    certificateBase64,
    password,
    'cms-attached',
  );
};

const createSignOrderRequest = (
  certificate: ParsedCertificate,
  orderId: number,
  orderCms: string,
): SignOrder => ({
  signFactor: 'EDS',
  certificateSn: certificate.certificateSerialNumber,
  certificateCaSn: certificate.certificateCSKSerialNumber,
  signatures: { [orderId]: orderCms },
});

const SignCertificateDetails: React.FC = () => {
  const { mode } = useCertificateDetails();

  return mode === KeyType.TOKEN ? <AddCertToken /> : <AddCertFile />;
};

const fetchOnMount: FetchOnMount<Props> = async (
  { customer, props },
  { setFields, setPayload, updateMode, fetchData },
) => {
  const mode = updateMode(customer.config);

  const filter = {
    customerIds: [customer.id],
    edrpou: customer.taxCode,
  };

  const [signData] = await Promise.all([
    api.payments.signCertificateOrder(props.orders.map(o => o.id)),
    fetchData(mode, filter),
  ]);

  setPayload({ signData });
  setFields({ customerId: `${customer.id}` });
};

const createOnSave: CreateOnSave<Props> =
  ({ payload, customers, props }, validatePassword) =>
  async ({ edsKey, customerId: customerIdStr, password }) => {
    const { edsKeyOptions, signData } = payload;
    const customerId = Number(customerIdStr);

    const certificate = edsKeyOptions.find(it => it.value === edsKey).content;
    const customerIdsWithOrder = toUniqueArray(props.orders.map(o => o.customerId));

    const customersMap = customers.reduce((acc: { [key: string]: boolean }, customer) => {
      acc[customer.id] = customer.allowedForUserCert;
      return acc;
    }, {});

    let orderIds;

    if (customerIdsWithOrder.length > 1) {
      orderIds = props.orders
        .filter(o => {
          if (certificate.isMultiKey && customerId !== o.customerId) {
            return customersMap[o.customerId];
          }

          return customerId === o.customerId;
        })
        .map(o => o.id);
    } else {
      orderIds = props.orders.map(o => o.id);
    }

    await api.certificate.validateKeyStorePassword(password, {
      Description: certificate.Description,
      Serial: certificate.Serial,
      Hw: certificate.hw,
    });

    validatePassword();

    let signOrdersRequest;

    const isUmcaWithMultisign = await isUmcaVersionAvailable();

    if (isUmcaWithMultisign) {
      const [signatureList, cmsOrderList] = orderIds.reduce(
        ([signList, cmsList], orderId) => {
          const newSignList = [...signList, signData[orderId].signatureString];
          const newCmsList = [...cmsList, signData[orderId].cms ?? null];

          return [newSignList, newCmsList];
        },
        [[], []],
      );

      const hasSomeCms = cmsOrderList.some(cms => cms !== null);

      const newCmsList = await api.certificate.multiSign(
        certificate.certBase64,
        password,
        signatureList,
        hasSomeCms ? cmsOrderList : undefined,
      );

      signOrdersRequest = orderIds.map((orderId, index) =>
        createSignOrderRequest(certificate, orderId, newCmsList[index]),
      );
    } else {
      const signOrdersPromises: Promise<SignOrder>[] = orderIds.map(async orderId => {
        const orderCms = await signOrder(
          signData[orderId],
          orderId,
          certificate.certBase64,
          password,
        );
        return createSignOrderRequest(certificate, orderId, orderCms);
      });

      const [...signedOrders] = await safeResolvedPromises(signOrdersPromises);

      signOrdersRequest = signedOrders.filter(item => isExist(item)) as SignOrder[];
    }

    const signingOrders = await signOrdersInChunks(signOrdersRequest);

    const hasError = isExist(signingOrders.find(item => item.hasError));

    if (props.ordersCount > 1 || hasError) {
      const errors = signingOrders.filter(item => item.hasError);
      const successCount = signingOrders.length - errors.length;

      await confirmModal<string>(
        AgreementFormResult,
        { errors, successCount, ordersCount: props.ordersCount },
        650,
      );
    }

    props.resolve(true);
  };

const createFilteringCustomers: CreateFilteringCustomers = customerIds => customerOption => {
  return customerIds.includes(+customerOption.value);
};

export const CertificateSign = withCertificate<Props>({
  fetchOnMount,
  createOnSave,
  createFilteringCustomers,
  allowCancel: true,
  allowedMultikey: true,
  labelButtonOnSave: 'front.certificates.sign-button.label',
  title: 'front.certificates.modal-umca-title.label',
  validatePasswordError: validateMessages.passwordValidationSignCertificate,
  filteringParams: {
    existingInSystem: EXISTING_IN_SYSTEM,
  },
})(SignCertificateDetails);
