import { api } from 'api';
import { isCipherAvailable } from 'api/CertificateService/CipherModule/CipherService';
import { CertificateStatus, KeyType, UserKeystoreType } from 'api/CertificateService/enums';
import { DemandResponse } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { isVisibleAction } from 'pages/Payments/utils';
import { NoConnectionModal } from 'pages/Profile/Certificates/Modals/NoConnectionModal';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';
import { switchSignApplications } from 'pages/utils/SignOrders/utils';
import { checkHasConfirmationFactor, toUniqueArray } from 'pages/utils/utils';

import { KepPinTokenConfirmationPaymentsModal } from './KepPinTokenConfirmationPaymentsModal';
import { UmkaPinTokenConfirmationPaymentsModal } from './UmkaPinTokenConfirmationPaymentsModal';

export const multiplyPinTokenConfirmationPayment = async (
  orders: DemandResponse[],
): Promise<void> => {
  const filteredOrders = orders.filter(order =>
    isVisibleAction(order, OrderActionType.CONFIRM_AF_PIN),
  );

  const customerIds = toUniqueArray(orders.map(item => item.base.customerId));

  await pinTokenConfirmationPayments(
    filteredOrders.map(({ id }) => id),
    customerIds,
  );
};

export const pinTokenConfirmationPayments = async (
  ids: number[],
  customerIds: number[],
): Promise<void> => {
  const hasAccessToConfirm = await checkHasConfirmationFactor(ids);

  if (!hasAccessToConfirm) {
    return;
  }

  const certificates = await api.certificate.getCertificates(customerIds, true, {
    statuses: CertificateStatus.Active,
    keyType: KeyType.TOKEN,
  });

  const filteredOnlyTokenCertificates = certificates.filter(
    cert => cert.userKeystoreType === UserKeystoreType.HardWired,
  );

  const result = await switchSignApplications({
    certificates: filteredOnlyTokenCertificates,
    warningMessage: 'front.confirm-modal.switch-login.label',
  });

  if (!result) {
    return;
  }

  const useAvtorCsk = result === AgreementModalResult.No;
  const isConnect = useAvtorCsk
    ? await api.certificate.isUmcaAvailable()
    : await isCipherAvailable();

  if (isConnect) {
    return useAvtorCsk
      ? await confirmModal(UmkaPinTokenConfirmationPaymentsModal, {
          ids,
          certificates,
          orderCount: ids.length,
        })
      : await confirmModal(KepPinTokenConfirmationPaymentsModal, {
          ids,
          certificates,
          orderCount: ids.length,
        });
  }

  await confirmModal(NoConnectionModal, {
    description: useAvtorCsk
      ? 'front.certificates.error-modal-umca-text.label'
      : 'front.certificates.error-modal-cipher-text.label',
    useCipherApp: !useAvtorCsk,
  });
};
