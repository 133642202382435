import * as React from 'react';

import { api } from 'api';
import { KeyType } from 'api/CertificateService/enums';
import { Token } from 'api/CertificateService/interfaces';
import { ActionEnum, OrderActionType } from 'api/enums';
import { Option } from 'api/Service';
import { withForm } from 'components/forms/withForm';
import { getTranslateAction } from 'pages/Payments/general';
import { validatePasswordWithException } from 'pages/Profile/Certificates/PasswordValidator';
import { isAvtorCertificate } from 'pages/Profile/Certificates/utils';
import { WrapperAgreePaymentInfoForm } from 'pages/utils/components/WrapperAgreePaymentInfoForm/WrapperAgreePaymentInfoForm';
import { ConfirmationPinTokenForm } from 'pages/utils/ConfirmationPayments/components/ConfimationPinTokenFrom/ConfirmationPinTokenForm';

import { usePinTokenConfirmation } from './hooks/usePinTokenConfirmation';
import { ConfirmationPaymentsPinTokenProps } from './interfaces';
import { checkHasActiveCertificate, checkPinTokenSign } from './utils';

const UmkaPinTokenConfirmationPaymentsForm = ({
  certificates,
  ids,
  ordersCount,
  resolve,
}: ConfirmationPaymentsPinTokenProps) => {
  const { progress, error, payload, handleSubmit, fetchToken, getFormData } =
    usePinTokenConfirmation<Option<Token>, Token>({
      ids,
      getAvailableToken: async () => await api.certificate.getTokens(KeyType.TOKEN),
    });

  const onSave = async () => {
    const { token: tokenSerial, password } = getFormData();
    const token = payload.tokenOptions?.find(item => item.value === tokenSerial).content;
    validatePasswordWithException(password, '', 'front.form.pin-code.error');

    try {
      await api.certificate.changePin(password, password, token, ActionEnum.ConfirmAfPin);
    } catch (error: any) {
      await checkPinTokenSign({
        signedString: '',
        resolve,
        ordersCount,
      });
    }

    const tokenCertificates = await api.certificate.getCertifcatesUmka({
      Hw: true,
      ReadCertificates: true,
      EnfoldFileKeyStores: false,
      Serial: token.Serial,
    });

    const certificatesSN = tokenCertificates?.map(cert => cert.certificateSerialNumber);

    const filteredCertificates = certificates.filter(certificate =>
      isAvtorCertificate(certificate),
    );

    await checkHasActiveCertificate({ filteredCertificates, certificatesSN, resolve });

    let signedString = '';

    for (const cert of filteredCertificates) {
      if (!signedString) {
        signedString = await api.certificate.sign(
          payload.dataForSign,
          cert.certificate,
          password,
          'cms-attached',
        );
      } else {
        signedString = await api.certificate.sign(
          payload.dataForSign,
          cert.certificate,
          password,
          null,
          signedString,
        );
      }
    }

    await checkPinTokenSign({
      signedString,
      resolve,
      ordersCount,
    });
  };

  return (
    <WrapperAgreePaymentInfoForm
      title={getTranslateAction(OrderActionType.CONFIRM_AF_PIN)}
      confirmButtonLabel="front.confirm-modal.confirm.label"
      progress={progress}
      error={error}
      handleSubmit={e => handleSubmit(onSave, e)}
      tableRows={payload?.tableRows}
      resolve={resolve}
    >
      <ConfirmationPinTokenForm tokenOptions={payload?.tokenOptions} fetchToken={fetchToken} />
    </WrapperAgreePaymentInfoForm>
  );
};

export const UmkaPinTokenConfirmationPaymentsModal = withForm(UmkaPinTokenConfirmationPaymentsForm);
