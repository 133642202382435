import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { DemandGroupTypes, DemandType } from 'api/DemandsService/enums';
import { DemandResponse, DemandsGridFilter } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { NEW } from 'navigations/routes';
import { getSubmitPage } from 'pages/Demands/utils';
import { getTranslateAction } from 'pages/Payments/general';
import { signCertificateOrders, signSmsOrders } from 'pages/utils/SignOrders/signOrders';

import { DemandPageFilter } from './DemandFilter';

export const typeGroups = [
  DemandGroupTypes.SalaryDemands,
  DemandGroupTypes.CreditOrders,
  DemandGroupTypes.DepositOrders,
  DemandGroupTypes.CardOrders,
  DemandGroupTypes.CashOperationOrders,
];

const fetchDemands = async (filter: DemandsGridFilter & { demandType: string[] }) =>
  await api.demands.getDemands({
    ...filter,
    typeGroups,
  });

const filterByAction = (row: DemandResponse, action: OrderActionType) => {
  return row.actions.some(item => item.actionName === action);
};

const columns: TableColumnProps<DemandResponse>[] = [
  {
    label: 'front.demands-table.date.label',
    render: row => <DataColumn title={format.date(row.base.orderDate)} />,
    sort: 'orderDate',
    showDesktop: true,
    showTablet: true,
    showMobile: false,
  },
  {
    label: 'front.demands-table.number.label',
    render: row => <DataColumn title={row.base.orderNumber} />,
    sort: 'orderNumberNumeric',
    showDesktop: true,
    showTablet: false,
    showMobile: false,
  },
  {
    label: 'front.demands-table.type.label',
    render: row => <DataColumn title={translate(row.base.translatedOrderType)} />,
    sort: 'orderType',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    label: 'front.demands-table.status.label',
    render: row => (
      <DataColumn>
        <Status color={getStatusColor(row.base.orderState)}>{row.base.orderStateLabel}</Status>
      </DataColumn>
    ),
    sort: 'orderState',
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
  {
    renderMenu: row => [
      {
        label: getTranslateAction(OrderActionType.EDIT),
        onClick: () => getSubmitPage({ id: row.id, type: row.base.orderType }),
        isVisible:
          filterByAction(row, OrderActionType.EDIT) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.SIGN),
        onClick: async () => {
          const action = row.actions.find(item => item.actionName === OrderActionType.SIGN);
          if (
            action &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS'))
          ) {
            await signCertificateOrders([{ id: row.id, customerId: row.base.customerId }]);
          } else {
            await signSmsOrders([row.id]);
          }
        },
        isReloadable: true,
        isVisible:
          filterByAction(row, OrderActionType.SIGN) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.SIGN,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.PRINT),
        onClick: async () => await api.demands.getOrderPdf(row.id, true),
        isVisible:
          filterByAction(row, OrderActionType.PRINT) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.VIEW,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.COPY),
        onClick: () => getSubmitPage({ id: NEW, type: row.base.orderType, copyFrom: `${row.id}` }),
        isVisible:
          filterByAction(row, OrderActionType.COPY) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.DELETE),
        onClick: async () => await api.demands.deleteOrder(row.id),
        isReloadable: true,
        confirmMessage: 'front.demand-filter.actions-delete.label',
        isVisible:
          filterByAction(row, OrderActionType.DELETE) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.REJECT),
        onClick: async () => await api.demands.executeAction(row.id, OrderActionType.REJECT),
        isReloadable: true,
        isVisible:
          filterByAction(row, OrderActionType.REJECT) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
      },
      {
        label: getTranslateAction(OrderActionType.REVOKE),
        onClick: async () => {
          await api.payments.withdrawOrders([row.id]);
          await confirmationModal(
            translate('front.working-documents-table.actions-revoke-sent.demand.label'),
            true,
            false,
          );
        },
        isVisible:
          filterByAction(row, OrderActionType.REVOKE) &&
          allowDemandAction(
            row.base.orderType as DemandType,
            OrderActionType.EDIT,
            row.base.customerId,
          ),
        confirmMessage: 'front.working-documents-table.actions-revoke-confirmation.demand.label',
      },
    ],
    showDesktop: true,
    showTablet: true,
    showMobile: true,
  },
];

const actions: Action<DemandResponse>[] = [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async (selected: DemandResponse[]) => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      );
      await signSmsOrders(orders.map(item => item.id));
    },
    isReloadable: true,
    isVisible: (selected: DemandResponse[]) => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN && action.confirmation.includes('SMS'),
        ),
      );
      return !!orders.length;
    },
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async (selected: DemandResponse[]) => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      );
      await signCertificateOrders(
        orders.map(item => ({ id: item.id, customerId: item.base.customerId })),
      );
    },
    isReloadable: true,
    isVisible: (selected: DemandResponse[]) => {
      const orders = selected.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes('Signature') || action.confirmation.includes('EDS')),
        ),
      );
      return !!orders.length;
    },
  },
  {
    label: 'front.working-documents-table.actions-revoke.label',
    action: async (selected: DemandResponse[]) => {
      const orders = selected.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.REVOKE) &&
          allowDemandAction(
            item.base.orderType as DemandType,
            OrderActionType.EDIT,
            item.base.customerId,
          ),
      );
      await api.demands.withdrawOrders(orders.map(item => item.id));
    },
    color: 'secondary',
    isVisible: (selected: DemandResponse[]) =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.REVOKE),
      ),
  },
  {
    label: getTranslateAction(OrderActionType.DELETE),
    action: async (selected: DemandResponse[]) => {
      const orders = selected.filter(
        item =>
          item.actions.some(action => action.actionName === OrderActionType.DELETE) &&
          allowDemandAction(
            item.base.orderType as DemandType,
            OrderActionType.EDIT,
            item.base.customerId,
          ),
      );
      for (let i = 0; i < orders.length; i += 1) {
        await api.demands.deleteOrder(orders[i].id);
      }
    },
    color: 'secondary',
    confirmMessage: 'front.demand-filter.actions-delete.label',
    isVisible: (selected: DemandResponse[]) =>
      selected.some(item =>
        item.actions.some(action => action.actionName === OrderActionType.DELETE),
      ),
    isReloadable: true,
  },
];

const onRowClick = ({ id, base: { orderType } }: DemandResponse) =>
  getSubmitPage({ id, type: orderType });

export const DemandsPage = () => (
  <Page title="front.demands-table.label">
    <DataTable
      name={TableNamesEnum.InternalDemandsTable}
      columns={columns}
      fetchRows={fetchDemands}
      actions={actions}
      defaultSort="orderDate,id"
      defaultOrder={SortOrder.Desc}
      filterComponent={DemandPageFilter}
      onRowClick={onRowClick}
    />
  </Page>
);
