import * as React from 'react';
import { Action, TableColumnProps } from 'react-data-table';
import { useRouteMatch } from 'react-router';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { downloadFile } from 'api/backend';
import { DemandType } from 'api/DemandsService/enums';
import { DemandResponse, DemandsGridFilter } from 'api/DemandsService/interfaces';
import { OrderActionType } from 'api/enums';
import { ExportType } from 'api/ExportService';
import { GridRequest, GridResponse, SortOrder } from 'api/Service';
import { getStatusColor } from 'api/utils';
import { Page } from 'components/layout/Page/Page';
import { Status } from 'components/layout/Status';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { DEFAULT_WIDTH, PrintingDocuments } from 'components/PrintingDocuments/PrintingDocuments';
import { DataColumn } from 'components/Table/DataColumn';
import { DataTable } from 'components/Table/DataTable';
import * as format from 'components/utils/format';
import config from 'config';
import { getSafeTranslation, translate } from 'i18n/translator';
import { allowDemandAction } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { NEW } from 'navigations/routes';
import {
  isAuthLetter,
  isDemandActionVisible,
  isFEAMessage,
  isSignAuthLetterFiles,
} from 'pages/Demands/utils';
import { getTranslateAction } from 'pages/Payments/general';
import { signCertificateOrders, signSmsOrders } from 'pages/utils/SignOrders/signOrders';

import { PartialDemandsFilter } from './PartialDemandsFilter';

const getTranslationInType = (order: DemandResponse): string => {
  const topicField = order?.dynamicFields?.find(item => item.field.code === 'topic')?.data[0];
  const code = topicField?.fieldValue;

  const translations: Partial<Record<DemandType, string>> = {
    [DemandType.AuthLetter]: topicField?.textValue,
    [DemandType.FEAMessage]: getSafeTranslation(
      `front.partial-demands-table.fea-message.topic.${code?.toLowerCase()}.label`,
    ),
    [DemandType.AcquiringNewTerminalOrder]: order.base.translatedOrderType,
  };

  return translations[order.base.orderType as DemandType];
};

const getTranslationInTitleColumn = (orderType: DemandType): string => {
  const translationsInTitle: Partial<Record<DemandType, string>> = {
    [DemandType.AuthLetter]: 'front.demands-table.type-letter.label',
    [DemandType.FEAMessage]: 'front.demands-table.type-operation.label',
    [DemandType.AcquiringNewTerminalOrder]: 'front.demands-table.type.label',
  };

  return translationsInTitle[orderType];
};

const getColumns = (orderType: DemandType): TableColumnProps<DemandResponse>[] => {
  return [
    {
      label: 'front.mails-table.date.label',
      render: row => <DataColumn title={format.date(row.base.orderDate)} />,
      sort: 'orderDate',
      showDesktop: true,
      showTablet: true,
      showMobile: true,
    },
    {
      label: 'front.working-documents-table.number.label',
      render: row => <DataColumn title={row.base.orderNumber} />,
      sort: 'orderNumberNumeric',
      showDesktop: true,
      showTablet: true,
      showMobile: false,
    },
    {
      label: 'front.demands-table.organization.label',
      render: row => <DataColumn title={row.base.customerName} />,
      sort: 'customer.name',
      showDesktop: true,
      showTablet: true,
      showMobile: true,
    },
    {
      label: getTranslationInTitleColumn(orderType),
      render: row => <DataColumn title={getTranslationInType(row)} />,
      sort: 'dynamicText.topic',
      showDesktop: true,
      showTablet: true,
      showMobile: true,
    },
    isFEAMessage(orderType)
      ? {
          label: 'front.demands-table.theme.label',
          render: row => (
            <DataColumn
              title={
                row.dynamicFields.find(field => field.field.code === 'subject')?.data[0]?.fieldValue
              }
            />
          ),
          sort: 'dynamicText.subject',
          showDesktop: true,
          showTablet: false,
          showMobile: false,
        }
      : null,
    {
      label: 'front.mails-table.status.label',
      render: doc => (
        <DataColumn>
          <Status color={getStatusColor(doc?.base.orderState)}>{doc.base.orderStateLabel}</Status>
        </DataColumn>
      ),
      sort: 'orderState',
      showDesktop: true,
      showTablet: false,
      showMobile: false,
    },
    isAuthLetter(orderType)
      ? {
          label: 'front.demands-table.theme-letter.label',
          render: row => (
            <DataColumn
              title={
                row.dynamicFields.find(field => field.field.code === 'subject')?.data[0]?.fieldValue
              }
            />
          ),
          sort: 'dynamicText.subject',
          showDesktop: true,
          showTablet: false,
          showMobile: false,
        }
      : null,
    isAuthLetter(orderType)
      ? {
          label: 'front.demands-table.text-letter.label',
          render: row => (
            <DataColumn
              title={
                row.dynamicFields.find(field => field.field.code === 'body')?.data[0]?.fieldValue
              }
            />
          ),
          sort: 'dynamicText.body',
          showDesktop: true,
          showTablet: false,
          showMobile: false,
        }
      : null,
    {
      renderMenu: order => [
        {
          label: getTranslateAction(OrderActionType.EDIT),
          onClick: () => {
            return goto(
              pages.partialDemands.tabs.details(orderType, order.id, { type: orderType }),
            );
          },

          isVisible: isDemandActionVisible(order, OrderActionType.EDIT),
        },
        {
          label: getTranslateAction(OrderActionType.DELETE),
          onClick: async () => await api.mail.deleteOrder(order.id),
          isVisible: isDemandActionVisible(order, OrderActionType.DELETE),
          confirmMessage:
            order.base.orderType === DemandType.AuthLetter
              ? 'front.demand-filter.actions-delete-message.label'
              : 'front.demand-filter.actions-delete.label',
          isReloadable: true,
        },
        {
          label: getTranslateAction(OrderActionType.SIGN),
          onClick: async () => {
            const action = order.actions.find(item => item.actionName === OrderActionType.SIGN);

            if (
              action &&
              (action.confirmation.includes(config.signTypes.SIGNATURE) ||
                action.confirmation.includes(config.signTypes.EDS))
            ) {
              await signCertificateOrders([
                {
                  id: order.id,
                  customerId: order.base.customerId,
                  isSignFiles: isSignAuthLetterFiles(order.dynamicFields),
                },
              ]);
            } else {
              await signSmsOrders([order.id]);
            }
          },
          isVisible: isDemandActionVisible(order, OrderActionType.SIGN),
          isReloadable: true,
        },
        {
          label: getTranslateAction(OrderActionType.COPY),
          onClick: () => {
            return goto(
              pages.partialDemands.tabs.details(orderType, NEW, {
                copyFrom: `${order.id}`,
                type: orderType,
              }),
            );
          },
          isVisible: isDemandActionVisible(order, OrderActionType.COPY),
        },
        {
          label: getTranslateAction(OrderActionType.PRINT),
          onClick: () =>
            downloadFile(`/v1/orders/get-pdf/${order.id}`, null, {
              isOrderDemand: true,
            }),
          isVisible: isDemandActionVisible(order, OrderActionType.PRINT),
        },
      ],
      showDesktop: true,
      showTablet: true,
      showMobile: true,
    },
  ];
};
const getActions = (orderType: DemandType): Action<DemandResponse>[] => [
  {
    label: 'front.misc.sign-subscribe-sms.label',
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            action.confirmation.includes(config.signTypes.SMS),
        ),
      );
      await signSmsOrders(orders.map(item => item.id));
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(
        item =>
          item.actions.some(
            action =>
              action.actionName === OrderActionType.SIGN &&
              action.confirmation.includes(config.signTypes.SMS),
          ) &&
          allowDemandAction(
            item.base.orderType as DemandType,
            OrderActionType.SIGN,
            item.base.customerId,
          ),
      ),
  },
  {
    label: 'front.misc.sign-electronic-sign.label',
    action: async data => {
      const orders = data.filter(item =>
        item.actions.some(
          action =>
            action.actionName === OrderActionType.SIGN &&
            (action.confirmation.includes(config.signTypes.SIGNATURE) ||
              action.confirmation.includes(config.signTypes.EDS)),
        ),
      );

      await signCertificateOrders(
        orders.map(order => ({
          id: order.id,
          customerId: order.base.customerId,
          isSignFiles: isSignAuthLetterFiles(order.dynamicFields),
        })),
      );
    },
    isReloadable: true,
    isVisible: selected =>
      selected.some(
        item =>
          item.actions.some(
            action =>
              action.actionName === OrderActionType.SIGN &&
              (action.confirmation.includes(config.signTypes.SIGNATURE) ||
                action.confirmation.includes(config.signTypes.EDS)),
          ) &&
          allowDemandAction(
            item.base.orderType as DemandType,
            OrderActionType.SIGN,
            item.base.customerId,
          ),
      ),
  },
  orderType === DemandType.FEAMessage && {
    label: getTranslateAction(OrderActionType.PRINT),
    action: async selected =>
      await confirmModal(
        PrintingDocuments,
        {
          data: selected.filter(item => isDemandActionVisible(item, OrderActionType.PRINT)),
          objectCode: ExportType.FEA_ORDERS,
        },
        DEFAULT_WIDTH,
      ),
    color: 'secondary',
    isVisible: selected =>
      selected.some(item => isDemandActionVisible(item, OrderActionType.PRINT)),
  },
];

const getLabel = (orderType: DemandType): string => {
  const labels: Partial<Record<DemandType, string>> = {
    [DemandType.AuthLetter]: 'front.auth-msg.title.label',
    [DemandType.AcquiringNewTerminalOrder]: 'front.acquiring.demands.title.label',
    [DemandType.FEAMessage]: 'front.menu.сurrency-documents.label',
  };

  return labels[orderType];
};

const getTableName = (orderType: DemandType): TableNamesEnum => {
  const tableNames: Partial<Record<DemandType, TableNamesEnum>> = {
    [DemandType.AuthLetter]: TableNamesEnum.AuthLetterPage,
    [DemandType.AcquiringNewTerminalOrder]: TableNamesEnum.AcquiringNewTerminalPage,
    [DemandType.FEAMessage]: TableNamesEnum.FEAMessagePage,
  };

  return tableNames[orderType];
};

export const PartialDemandsPage = () => {
  const {
    params: { orderType },
  } = useRouteMatch<{ orderType: DemandType }>();

  const fetchRows = React.useCallback(
    async (request: GridRequest): Promise<GridResponse<DemandResponse>> => {
      const filter: DemandsGridFilter = {
        ...request,
        orderType,
        isPaymentOrder: false,
      };
      return await api.demands.getDemands(filter);
    },
    [orderType],
  );

  const renderFilterComponent = React.useCallback(
    props => <PartialDemandsFilter orderType={orderType} {...props} />,
    [orderType],
  );

  const [tableName, label, columns, actions] = React.useMemo(
    () => [
      getTableName(orderType),
      getLabel(orderType),
      getColumns(orderType),
      getActions(orderType),
    ],
    [orderType],
  );

  return (
    <Page title={translate(label)}>
      <DataTable
        name={tableName}
        actions={actions}
        columns={columns}
        fetchRows={fetchRows}
        defaultSort="id"
        defaultOrder={SortOrder.Desc}
        filterComponent={renderFilterComponent}
        onRowClick={row =>
          goto(
            pages.partialDemands.tabs.details(orderType, row.id, {
              type: orderType,
            }),
          )
        }
      />
    </Page>
  );
};
